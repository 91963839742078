<div class="row">
    <div class="col-12">
        <div class="card">
            <div class="card-body">
                <h5 class="card-title d-flex align-items-center">
                    <div>
                        <i class="material-icons">
                            description
                        </i> Programar Servicios
                    </div>
                </h5>
                <div class="form-row">
                    <div class="form-group col-1">
                        <label class="col-form-label"> Ingrese Rut</label>
                    </div>
                    <div class="form-group col-2">
                        <input type="text" #txtRut class="form-control" placeholder="12345678-1"
                            [formControl]="rutControl" [(ngModel)]="rutSearch" name="rutSearch">
                    </div>

                    <div class="form-group col-1">
                        <button (click)="ListaCotizaciones(txtRut.value)" [disabled]="false"
                            class="btn btn-outline-warning my-2 my-sm-0 ">Cotizaciones</button>
                    </div>

                    <div align="right" class="form-group col-2">
                        <label class="col-form-label"> Ingrese Cotización</label>
                    </div>

                    <div class="form-group col-2">
                        <input type="text" #txtCotizacion class="form-control" placeholder="123456"
                            (focusout)="getCotizacionDet(txtRut.value,txtCotizacion.value)"
                            (keyup.enter)="getCotizacionDet(txtRut.value,txtCotizacion.value)"
                            [formControl]="cotizacionControl" [(ngModel)]="cotizacionSearch" name="cotizacionSearch">
                    </div>

                    <div class="form-group col-1">
                        <button (click)="getCotizacionDet(txtRut.value,txtCotizacion.value)"
                            class="btn btn-outline-warning my-2 my-sm-0 ">Buscar</button>
                    </div>
                </div>

                <div [formGroup]="datosCotizaciones" *ngFor="let item of cotizacionList">
                    
                </div>
                
                <div *ngIf="mostrarGrillaCot" class="form-row">
                    <ag-grid-angular style="width: 100%; height: 200px;" class="ag-theme-balham" [rowData]="rowDataCot"
                        [columnDefs]="columnDefsCot" [frameworkComponents]="frameworkComponents">
                    </ag-grid-angular>
                </div>

                
            </div>
        </div>
        <div class="card">
            <div class="card-body">
                <div [formGroup]="datosCliente" *ngFor="let item of cotizacion">
                    <div class="form-row">
                        <div align="center" class="form-group col-12">
                            <p> <b>Nombre: </b> {{item.NOMBRE}} <b> - Fecha: </b>{{item.FECHA}} <b> - Dirección:
                                </b>{{item.DIRECCION}} <b>- Ciudad: </b> {{item.CIUDAD}}
                                <b>- Referencia del Servicio: </b> {{item.REFERENCIA}} <b> - Total: $</b>{{item.TOTAL | number}}
                            </p>
                        </div>
                    </div>
                </div>
                <div *ngIf="mostrarGrilla" class="form-row">
                    <ag-grid-angular style="width: 100%; height: 200px;" class="ag-theme-balham" [rowData]="rowData"
                        [columnDefs]="columnDefs" [frameworkComponents]="frameworkComponents">
                    </ag-grid-angular>
                </div>
            </div>
        </div>
        <div *ngIf="mostrarOT" class="card">
            <div class="card-body">
                <div align="center">
                    <label><b>SERVICIO: </b> {{nombreServivicio}}</label>
                </div>
                <div class="form-row">
                    <div class="form-group col-12">
                        <mat-form-field class="example-full-width" appearance="fill">
                            <mat-label>Faena</mat-label>
                            <input matInput placeholder="Ingrese Faena" value="">
                        </mat-form-field>
                    </div>
                    <div class="form-group col-2">
                        <mat-form-field class="example-full-width" appearance="fill">
                            <mat-label>Origen</mat-label>
                            <input matInput placeholder="Ingrese Faena">
                        </mat-form-field>
                    </div>
                    <div class="form-group col-4">
                        <mat-form-field class="example-full-width" appearance="fill">
                            <mat-label>Dirección Retiro</mat-label>
                            <input matInput placeholder="Ingrese Faena">
                        </mat-form-field>
                    </div>
                    <div class="form-group col-3">
                        <mat-form-field class="example-full-width" appearance="fill">
                            <mat-label>Contacto Retiro</mat-label>
                            <input matInput placeholder="Ingrese Faena">
                        </mat-form-field>
                    </div>
                    <div class="form-group col-2">
                        <mat-form-field class="example-full-width" appearance="fill">
                            <mat-label>Fecha Servicio</mat-label>
                            <input matInput placeholder="Ingrese Faena">
                        </mat-form-field>
                    </div>
                    <div class="form-group col-1">
                        <mat-form-field class="example-full-width" appearance="fill">
                            <mat-label>Hora</mat-label>
                            <input matInput placeholder="Ingrese Faena">
                        </mat-form-field>
                    </div>
                </div>
                <div class="form-row">
                    <div class="form-group col-2">
                        <mat-form-field class="example-full-width" appearance="fill">
                            <mat-label>Destino</mat-label>
                            <input matInput placeholder="Ingrese Destino">
                        </mat-form-field>
                    </div>
                    <div class="form-group col-4">
                        <mat-form-field class="example-full-width" appearance="fill">
                            <mat-label>Dirección Entrega</mat-label>
                            <input matInput placeholder="Ingrese Direccioón">
                        </mat-form-field>
                    </div>
                    <div class="form-group col-3">
                        <mat-form-field class="example-full-width" appearance="fill">
                            <mat-label>Contacto Entrega</mat-label>
                            <input matInput placeholder="Ingrese Contacto">
                        </mat-form-field>
                    </div>
                    <div class="form-group col-2">
                        <mat-form-field class="example-full-width" appearance="fill">
                            <mat-label>Fecha Entrega</mat-label>
                            <input matInput placeholder="Fecha">
                        </mat-form-field>
                    </div>
                    <div class="form-group col-1">
                        <mat-form-field class="example-full-width" appearance="fill">
                            <mat-label>Hora</mat-label>
                            <input matInput placeholder="Hora">
                        </mat-form-field>
                    </div>
                </div>

                <div class="form-row">
                    <div class="form-group col-2">
                        <mat-form-field class="example-full-width" appearance="fill">
                            <mat-label>Patente Tracto</mat-label>
                            <mat-select>
                                <mat-option value="option">DSDP-44</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <div class="form-group col-2">
                        <mat-form-field class="example-full-width" appearance="fill">
                            <mat-label>Patente Semiremolque</mat-label>
                            <mat-select>
                                <mat-option value="option">DSDP-44</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <div class="form-group col-2">
                        <mat-form-field class="example-full-width" appearance="fill">
                            <mat-label>Operador</mat-label>
                            <mat-select>
                                <mat-option value="option">Danilo Osorio</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <div class="form-group col-2">
                        <button (click)="addOT()"
                            class="btn btn-outline-warning my-2 my-sm-0 ">Generar OT</button>
                    </div>
                    <div class="form-group col-2">
                        <button (click)="cancel()"
                            class="btn btn-outline-warning my-2 my-sm-0 ">Cancelar</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>