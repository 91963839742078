
export class Codigo {
    
    public NOMBRE: string;
    public EMPRESA: string;
    public RUT: string;
    public CODIGO: string;
    public ESTADO: string;
    public OTRO: string;
    constructor(

    ) { }
}
