<br><br>
<form id="limpiarForm" novalidate [formGroup]="limpiarForm">

    <div class="form-row">

        <div class="form-group col-md-3">
            <label for="inputEmail4">DESDE</label>
            <mat-form-field class="form-control">
                <input [(ngModel)]="selectedFecha" #txtInput1 matInput [matDatepicker]="picker" formControlName="fecha" placeholder="Fecha Desde">
                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                <mat-datepicker #picker></mat-datepicker>
            </mat-form-field>
            <mat-checkbox (click)="fechaActual()" formControlName="fechaActual">Fecha actual</mat-checkbox>
        </div>
        <div class="form-group col-md-3">
            <label for="inputEmail4">HASTA</label>
            <mat-form-field class="form-control">
                <input [(ngModel)]="selectedFechaHasta" #txtInput4 matInput [matDatepicker]="picker2" formControlName="fechahasta" placeholder="Fecha Hasta">
                <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
                <mat-datepicker #picker2></mat-datepicker>
            </mat-form-field>
            <mat-checkbox (click)="fechaActual2()" formControlName="fechaActual2">Fecha actual</mat-checkbox>
        </div>
        <div class="form-group col-md-3">
            <label for="inputPassword4">EMPRESA</label>
            <mat-form-field class="form-control">
                <mat-label>Seleccione Empresa</mat-label>
                <mat-select [(ngModel)]="selectedEmpresa" #txtInput2 formControlName="empresa">
                    <mat-option *ngFor="let empresa of empresas" value="{{ empresa.Codigo }}">{{ empresa.Empresa }}</mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        <div class="form-group col-md-2">
            <label>Rut</label>
            <input type="text" #txtCodigo class="form-control" placeholder="Rut" formControlName="rut" [(ngModel)]="selectedRut" name="rut">
        </div>
       <div>
           <div>
               <label></label>
        </div>
        <div class="form-inline my-2 my-lg-2">
            <button mat-raised-button (click)="irUsuario( selectedFecha,selectedFechaHasta,selectedHora,selectedPatente,selectedRut,selectedEmpresa )" class="btn btn-login my-2 my-sm-0" type="submit">Buscar</button>
        </div>
        <div class="form-inline my-2 my-lg-2">
            <button mat-raised-button (click)="limpiar()" class="btn btn-outline-warning my-2 my-sm-0 " type="button ">Limpiar</button>
        </div>
       

        <!-- <div class="form-group col-md-3">
            <label for="inputPassword4">HORA</label>
            <mat-form-field class="form-control">
                <mat-label>Seleccione la hora</mat-label>
                <mat-select [(ngModel)]="selectedHora" #txtInput2 formControlName="hora">
                    <mat-option *ngFor="let hora of horas" value="{{ hora.HORA }}">{{ hora.HORA }}</mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        <div class="form-group col-md-3">
            <label for="inputPassword4">PATENTE</label>
            <mat-form-field class="form-control">
                <mat-label>Seleccione la Patente</mat-label>
                <mat-select [(ngModel)]="selectedPatente" #txtInput3 formControlName="patente">
                    <mat-option *ngFor="let patente of patente" value="{{ patente.PATENTE }}">{{ patente.PATENTE }}</mat-option>
                </mat-select>
            </mat-form-field>
        </div> -->

    </div>

    <br>
    <div class="container"  *ngIf="excel===true">
        <button mat-raised-button (click)="exportAsXLSX()" class="btn btn-outline-info my-2 my-sm-0" type="submit">Exportar Excel
            <i class="fas fa-file-export"></i>
        </button>
        <!-- <button mat-raised-button (click)="capturarContenido()" class="btn btn-outline-primary my-2 my-sm-0" type="submit">Descargar PDF
        <i class="fas fa-file-export"></i>
    </button> -->
        <!-- Table Desktop -->
        <!-- Table Mobile -->
        <div class="example-container mat-elevation-z8">
            <table if="limpiarForm" mat-table [dataSource]="dataSourceTable">

                <!-- Codigo -->
                <ng-container matColumnDef="codigoQr" sticky>
                    <th mat-header-cell *matHeaderCellDef> Codigo Qr </th>
                    <td mat-cell *matCellDef="let element"> {{element.CODIGO_QR}} </td>
                </ng-container>

                <!--  rut -->
                <ng-container matColumnDef="rut">
                    <th class="SelectHour" mat-header-cell *matHeaderCellDef> Rut </th>
                    <td mat-cell *matCellDef="let element"> {{element.RUT}} </td>
                </ng-container>

                <!-- nombre -->
                <ng-container matColumnDef="nombre">
                    <th mat-header-cell *matHeaderCellDef> Nombre </th>
                    <td mat-cell *matCellDef="let element"> {{element.NOMBRE}} </td>
                </ng-container>

                <!-- Conductor -->
                <ng-container matColumnDef="conductor">
                    <th mat-header-cell *matHeaderCellDef> Conductor </th>
                    <td mat-cell *matCellDef="let element"> {{element.CONDUCTOR}} </td>
                </ng-container>

                <!-- fecha -->
                <ng-container matColumnDef="fecha">
                    <th mat-header-cell *matHeaderCellDef> Fecha</th>
                    <td mat-cell *matCellDef="let element"> {{element.FECHA}} </td>
                </ng-container>

                <!-- Hora ingreso -->
                <ng-container matColumnDef="hora">
                    <th mat-header-cell *matHeaderCellDef>

                        <mat-form-field class="SelectHour">
                            <mat-label>Hora</mat-label>
                            <mat-select [formControl]="toppings" multiple>
                                <mat-option *ngFor="let topping of hours" (click)="filterHours()" [value]="topping">{{topping.HORA}}</mat-option>
                            </mat-select>
                        </mat-form-field>

                    </th>
                    <td mat-cell style="text-align: center;" *matCellDef="let element"> {{element.HORA}} </td>
                </ng-container>

                <!-- Empresa -->
                <ng-container matColumnDef="origen">
                    <th mat-header-cell *matHeaderCellDef> 

                    <mat-form-field class="SelectHour">
                        <mat-label>Origen</mat-label>
                        <mat-select [formControl]="toppings" multiple>
                            <mat-option *ngFor="let topping of origin" (click)="filterOrigen()" [value]="topping">Campanario</mat-option>
                        </mat-select>
                    </mat-form-field>
                    </th>

                    <td mat-cell *matCellDef="let element"> {{element.ORIGEN}} </td>
                </ng-container>

                <ng-container matColumnDef="destino">
                    <th mat-header-cell *matHeaderCellDef> 

                    <mat-form-field class="SelectHour">
                        <mat-label>Destino</mat-label>
                        <mat-select [formControl]="toppings" multiple>
                            <mat-option *ngFor="let topping of destinity" (click)="filterDestino()" [value]="topping">Campanario</mat-option>
                        </mat-select>
                    </mat-form-field>
                    </th>

                    <td mat-cell *matCellDef="let element"> {{element.DESTINO}} </td>
                </ng-container>

                <!-- Cena Noche -->
                <!-- <ng-container matColumnDef="destino">
                    <th mat-header-cell *matHeaderCellDef> Destino </th>
                    <td mat-cell *matCellDef="let element"> {{element.DESTINO}} </td>
                </ng-container> -->

                <!-- Desayuno -->
                <ng-container matColumnDef="horaIng">
                    <th mat-header-cell *matHeaderCellDef> Hora Ingreso </th>
                    <td mat-cell *matCellDef="let element"> {{ element.HORA_INGRESO }} </td>
                </ng-container>

                <!-- Almuerzo -->
                <ng-container matColumnDef="horaBajada">
                    <th mat-header-cell *matHeaderCellDef> Hora bajada </th>
                    <td mat-cell *matCellDef="let element"> {{ element.HORA_BAJADA}} </td>
                </ng-container>

                <!-- Cena -->
                <ng-container matColumnDef="empresa">
                    <th mat-header-cell *matHeaderCellDef> Empresa </th>
                    <td mat-cell *matCellDef="let element"> {{ element.EMPRESA}} </td>
                </ng-container>

                <ng-container matColumnDef="star" stickyEnd>
                    <th mat-header-cell *matHeaderCellDef></th>
                    <td mat-cell *matCellDef="let element">
                        <mat-icon>more_vert</mat-icon>
                    </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumnsUsuarios"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumnsUsuarios;"></tr>
            </table>

        </div>
    </div>

<div class="container" *ngIf="loading===true ">
    <div class="col ">
        <div class="alert alert-info text-center ">
            <i class="fa fa-sync fa-spin fa-2x "></i>
            <h1>Buscando datos...</h1>
        </div>
    </div>
</div>
    <div class="container" *ngIf="user===true ">
        <div class="col ">
            <div class="alert alert-info text-center ">
                <!-- <i class="fa fa-sync fa-spin fa-2x "></i> -->
                <h1>No se encontraron datos...</h1>
            </div>
        </div>
    </div>

    <div class="row " *ngIf="error ">
        <div class="col ">
            <div class="alert alert-danger text-center ">
                <i class="fa fa-times fa-2x "></i>
                <h2>Error: {{ error.status }} </h2>
                <p>
                    {{ error.message }}
                    <br><br> {{ error.url }}
                </p>
            </div>
        </div>
    </div>