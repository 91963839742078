<app-Loginnavbar></app-Loginnavbar>

<div class="page-body-wrapper full-page-wrapper auth-page">
    <mat-card>
        <div class="content-wrapper d-flex align-items-center auth auth-bg-1 theme-one">

            <div class="row w-100">
                <div class="col-lg-4 mx-auto">
                    <h2 class="text-center mb-4" style="color: black;">Bienvenidos</h2>
                    <div class="auto-form-wrapper">
                        <form (ngSubmit)="onSubmit( log.value )" #log="ngForm">
                            <div class="form-group">
                                <label class="label">Usuario</label>
                                <div class="input-group">
                                    <input type="user" class="form-control" placeholder="Usuario" ngModel name="user" required>
                                    <div class="input-group-append">
                                        <span class="input-group-text">
                    <i class="mdi mdi-check-circle-outline"></i>
                  </span>
                                    </div>
                                </div>
                            </div>
                            <div class="form-group">
                                <label class="label">Contraseña</label>
                                <div class="input-group">
                                    <input type="password" class="form-control" placeholder="*********" ngModel name="pass" required>
                                    <div class="input-group-append">
                                        <span class="input-group-text">
                    <i class="mdi mdi-check-circle-outline"></i>
                  </span>
                                    </div>
                                </div>
                            </div>
                            <div class="form-group">
                                <button class="btn btn-login submit-btn btn-block">Acceder</button>
                                <button *ngIf="loading" [disabled]="true" class="btn btn-primary submit-btn btn-block">
                      <i class="fa fa-spin fa-sync"></i>
                      Espere...
                    </button>
                            </div>

                            <!-- <div class="text-block text-center my-3">
              <span class="text-small font-weight-semibold">No estas registrado? </span>
              <br>
              <a href="" class="text-black text-small">Crear una cuenta</a>
            </div> -->
                        </form>
                    </div>


                </div>
                <div class="col-lg-5 mx-auto">
                    <div class="auto-form-wrapper">
                        <img src="assets/imagenes/image_02.png" alt="logo" />
                    </div>
                </div>
            </div>
        </div>
    </mat-card>
    <!-- content-wrapper ends -->
</div>