import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { Usuario } from '../models/usuario.model';
import { Login } from '../models/login.model';
import { Router } from '@angular/router';
import { UsuarioComponent } from '../usuarios/ingreso-personal/ingreso-personal.component';
import { Observable, Subscriber } from 'rxjs';
import { Casino } from '../models/casino.model';
import { Empresas } from '../models/empresa.model';
import { Codigo } from '../models/codigo.model';
import { Ubicacion } from '../models/ubicacion.model';
import { Conductor } from '../models/conductor.model';
import { TipoServicio } from '../models/tiposervicio.model';
import { Producto } from '../models/producto.model';
import { Cotizacion } from '../models/cotizacion.model';
import { CotizacionDet } from '../models/cotizaciondet.model';
import { CotizacionList } from '../models/cotizacionList.models';




@Injectable({
  providedIn: 'root'
})
export class UsuarioService {
  private loggedIn = false;
  private logger = new Observable<boolean>((observer: Subscriber<boolean>) => {
    observer.next(this.loggedIn);
  });
   private url = 'http://186.64.113.72:8080';
   //private url = 'http://localhost:8080';  http://186.64.113.72/  168.232.167.143

  constructor( private http: HttpClient,
               private router: Router,
             ) { }

  getLogin( user: String, pass: String){
    localStorage.setItem('authParams', 'false');
    this.loggedIn = true;
    console.log("getLogin",localStorage.getItem('authParams'))
    console.log(this.url)
    return this.http.get<Login[]>(`${ this.url }/login?user=${ user }&password=${ pass }`);
  }

  getUsers() {
    return this.http.get(`${ this.url }/viajeshora`)
          .pipe(
            map( resp => resp['data'])
          );
  }

  getCodigoQR(codigoQR: string){
    return this.http.get<Codigo[]>(`${ this.url }/ConsultaPersonasQR?CODIGOQR=${ codigoQR }`);
    // .pipe(
    //   map( resp => resp['data'] )
    // );
  }

  getRut(rut: string){
    return this.http.get(`${ this.url }/ConsultaPersonas?RUT=${ rut }`);
    // .pipe(
    //   map( resp => resp['data'] )
    // );
  }
  getCondutorByRut(rut: string){
    return this.http.get<Conductor>(`${ this.url }/clientes?rut=${ rut }`);
  }

  getCodigo(codigo: string){
    return this.http.get<Conductor>(`${ this.url }/codigo?codigo=${ codigo }`);
  }
  
  addCotizacion(rut: string, referencia:string, usuario:string,neto:number,terminos:string,camabaja:string,portamaq:string,rampla:string,lmodular:string,dolly:string,extencionmodular:string,vigamodular:string,tractotiro:string){
    const params = 'cotizacionadd?rut='+rut+'&referencia='+referencia+'&usuario='+usuario+'&neto='+neto+'&terminos='+terminos+'&camabaja='+camabaja+'&portamaq='+portamaq+'&rampla='+rampla+'&lmodular='+lmodular+
    '&dolly='+dolly+'&extencionmodular='+extencionmodular+'&vigamodular='+vigamodular+'&tractotiro='+tractotiro;
    return this.http.get<Cotizacion[]>(`${ this.url }/${ params }`); 
  }

  addCotizacionDet(numero: string, id:string, codigo:string,nombre:string,unidad:string,cantidad:string,valunitario:string,valtotal:string,rut:string){
    const params = 'cotizaciondetadd?numero='+numero+'&id='+id+'&codigo='+codigo+'&nombre='+nombre+
    '&unidad='+unidad+'&cantidad='+cantidad+'&valunitario='+valunitario+'&valtotal='+valtotal+'&rut='+rut;
    return this.http.get<Cotizacion[]>(`${ this.url }/${ params }`); 
  }


  getCotizacionDet(rut:string,numero: string){
    const params = 'cotizaciondetget?rut='+rut+'&numero='+numero;
    return this.http.get<CotizacionDet[]>(`${ this.url }/${ params }`); 
  }

  getCotizacion(rut:string,numero: string){
    const params = 'cotizacionget?rut='+rut+'&numero='+numero;
    return this.http.get<Cotizacion[]>(`${ this.url }/${ params }`); 
  }

  getCotizacionList(rut:string){
    const params = 'cotizacionListget?rut='+rut;
    return this.http.get<CotizacionList[]>(`${ this.url }/${ params }`); 
  }

  updateConductor(rut: string, nombre: string, usuario: string, pass: string){
    const params = 'updateconductor?rut='+rut+'&nombre='+nombre+'&usuario='+usuario+'&pass='+pass;
    return this.http.get<Ubicacion[]>(`${ this.url }/${ params }`);  
  }

  getHora(){
    return this.http.get<Usuario[]>(`${ this.url }/viajeshora`);
  }

  getEmpresa(){
    return this.http.get<Usuario[]>(`${ this.url }/ViajesEmpresa`);
  }
  
  getOnlyEmpresa(){
    return this.http.get<Empresas[]>(`${ this.url }/ViajesEmpresa`);
  }

  getTipoServicio(){
    return this.http.get<TipoServicio[]>(`${ this.url }/tiposervicio`);
  }

  getProducto(tipo: string){
    const params = 'productos?tipo='+tipo;
    console.log("params",params);
    
    return this.http.get<Producto[]>(`${ this.url }/${ params }`);  
  }

  getPatente(){
    return this.http.get<Usuario[]>(`${ this.url }/viajesPatente`);
  }

  getUbicacion(codigoqr: string, ubicacion: string, fecha: string,tipo:string){
    const params = 'insertaticket?codigoqr='+codigoqr+'&ubicacion='+ubicacion+'&fecha='+fecha+'&tipo='+tipo;
    console.log("params",params);
    
    return this.http.get<Ubicacion[]>(`${ this.url }/${ params }`);  
  }


  getDatos(fecha: string, fechaFin: string, hora: string, patente: string, rut: string, empresa: string) {
    const params = 'viajesfecha?FECHAINI='+fecha+'&FECHAFIN='+fechaFin+'&HORA='+hora+'&PATENTE='+patente+'&RUT='+rut+'&EMPRESA='+empresa;
    return this.http.get<Usuario[]>(`${ this.url }/${ params }`);
    // return this.http.delete(`${ this.url }/viajes/${ codigoQr }.json`);

  }


  getUserById( id: string ) {
    return this.http.get(`${ this.url }/users/${ id }`)
          .pipe(
            map( resp => resp['data'])
          );
  }

  saveClientes(rut: string, nombre: string, direccion: string, giro: string, contacto: string,ciudad:string){
    const params = 'save_clientes?rut='+rut+'&nombre='+nombre+'&direccion='+direccion+'&giro='+giro+'&contacto='+contacto+'&ciudad='+ciudad;
    return this.http.get<Usuario[]>(`${ this.url }/${ params }`);
  }

  // buscarCodigo(){

  // }

  ingresoPersonal(codigo: number, rut: any, nombre: any, empresa: number, otro: any, estado: number){
    const params = 'insertarPersonas?nombre='+nombre+'&rut='+rut+'&Empresa='+empresa+'&codigoQR='+codigo+'&otro='+otro+'&estado='+estado;
    return this.http.get<Usuario[]>(`${ this.url }/${ params }`);
  }

  logout() {

    this.router.navigate(['/login']);
  }

  home() {

    this.router.navigate(['/home']);
  }

  ingreso() {

    this.router.navigate(['/personal']);
  }



    isAuth() {
    this.router.navigate(['/login']);
  }

  logOut() {
    localStorage.removeItem('authParams');
    this.loggedIn = false;
  }

  isLoggedIn(): Observable<boolean> {
    return this.logger;
  }

  logIn(provider: string, providerResponse: string) {
    localStorage.setItem('authParams', providerResponse);
    this.loggedIn = true;
  }

  getCasino(fechaDesde: string , fechaHasta: string ,rut: string, empresa : string){

    if (empresa == undefined)
       empresa = "";

   if (empresa == null)
       empresa = "";

   if (rut == undefined)
      rut = "";

   if (rut == null)
       rut = "";
  
   if (empresa== "" && rut== ""){
       const params = 'casinos?Fechaini='+fechaDesde+'&FechaFin='+fechaHasta;
      return this.http.get<Casino[]>(`${ this.url }/${ params }`);
   }
   else{
      const params = 'casinosFiltro?Fechaini='+fechaDesde+'&FechaFin='+fechaHasta +'&Rut='+rut +'&Empresa='+empresa;
      return this.http.get<Casino[]>(`${ this.url }/${ params }`);
   }

  }

}
