import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UsuarioService } from '../../services/usuario.service';
import { logging } from 'protractor';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styles: []
})
export class NavbarComponent implements OnInit {
  logg: boolean;
  login: string;
  nav = true;
  loggedIn: boolean ;
  navbarLogin: string;

  constructor( private router: Router,
               private serviceLogin: UsuarioService,
               private us: UsuarioService
    ) { 
      this.login = localStorage.getItem('login');
      console.log(JSON.parse(localStorage.getItem('authParams')));
  }

  ngOnInit() {
    // const login2 = localStorage.getItem('login');
    // console.log("",login2)
    // console.log("login JOSE",this.login)
    this.us.isLoggedIn().subscribe(loggedIn => {
      // this.loggedIn = loggedIn;
      console.log("ASDASD",loggedIn)
    });

    this.navbarLogin = localStorage.getItem('authParams')
    console.log("navbarLogin",localStorage.getItem('authParams'))

    
  }

  irUsuario( patente: string ) {

    if ( !patente ) {
      return;
    }

    this.router.navigate([ '/usuario', patente ]);

  }

  loggout(){
    this.loggedIn = false;
    localStorage.setItem('authParams','false');
    console.log(localStorage.getItem('authParams'))
    // this.login = localStorage.getItem('authParams');
    this.router.navigate([ '/login']);
  }

  logout() {
    this.nav = false;
    this.serviceLogin.logout();
  }

  
  home() {
    this.serviceLogin.home();
  }

  ingresoPersonal() {
    this.serviceLogin.ingreso();
  }

  

}
