import { Component, OnInit } from '@angular/core';
import { UsuarioService } from '../../services/usuario.service';
import { Router } from '@angular/router';
import { Login } from '../../models/login.model';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html'
})
export class LoginComponent implements OnInit {

  loading: boolean;
  loginResp: Login[] = [];
  login: string;
  loggedIn: boolean;
  authParams: any;
  login2:any;
  navbarLogin: any;
  constructor( private serviceLogin: UsuarioService,
    private us: UsuarioService,
               private route: Router) { }

  ngOnInit() {

    this.us.isLoggedIn().subscribe(loggedIn => {
      this.loggedIn = true;
      console.log("ASDASD",loggedIn)
    });
    console.log("LOGIN",localStorage.getItem('authParams'))
    this.login2= localStorage.getItem('authParams');
    console.log("login2",this.login2)
  }

  onSubmit( data : any ){
    // if( data.user === null ){
    //   Swal.fire('Error en el login', 'Error, no se permite campos vacíos!', 'error');
    // }
    this.serviceLogin.getLogin(data.user, data.pass)
    .subscribe( access => { 
      this.loginResp = access;
      this.acceso();
    });
  }

  acceso(){
    this.loading = true;
    if( this.loginResp[0].ACCESO == "OK"){
      this.route.navigate(['/conductor']);
    }else if( this.loginResp[0].ACCESO == "NOK"){
      this.loading = false;
      Swal.fire('Error en el login', 'Error, usuario o clave erronea!', 'error');
    }
  }

}
