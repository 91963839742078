import {OnInit, ViewChild, ChangeDetectorRef, Component } from '@angular/core';
import { Usuario } from '../../models/usuario.model';
import { Store } from '@ngrx/store';
import { AppState } from '../../store/app.reducer';
import * as usuariosActions from '../../store/actions';
import { Router } from '@angular/router';
import { UsuarioService } from '../../services/usuario.service';
import { DatePipe } from '@angular/common';
import { Hora } from '../../models/hora.model';
import { Patente } from '../../models/patente.model';
import { FormGroup, Validators, FormControl } from '@angular/forms';
import { MatSort, MatTableDataSource, DateAdapter, MAT_DATE_FORMATS, MatPaginator } from '@angular/material';
import { ExporterService } from '../../services/exporter.service';
import Swal from 'sweetalert2';
import { AppDateAdapter, APP_DATE_FORMATS } from '../date.adapter';
// import * as jsPDF from 'jsPDF';
// import html2canvas from 'html2canvas';
import { DeviceDetectorService } from 'ngx-device-detector';
import { unwatchFile } from 'fs';
import { Empresas } from 'src/app/models/empresa.model';

const ELEMENT_DATA: PeriodicElement[] = [
  {position: 1, name: 'Hydrogen', weight: 1.0079, symbol: 'H'},
  {position: 2, name: 'Helium', weight: 4.0026, symbol: 'He'},
  {position: 3, name: 'Lithium', weight: 6.941, symbol: 'Li'},
  {position: 4, name: 'Beryllium', weight: 9.0122, symbol: 'Be'},
  {position: 5, name: 'Boron', weight: 10.811, symbol: 'B'},
  {position: 6, name: 'Carbon', weight: 12.0107, symbol: 'C'},
  {position: 7, name: 'Nitrogen', weight: 14.0067, symbol: 'N'},
  {position: 8, name: 'Oxygen', weight: 15.9994, symbol: 'O'},
  {position: 9, name: 'Fluorine', weight: 18.9984, symbol: 'F'},
  {position: 10, name: 'Neon', weight: 20.1797, symbol: 'Ne'},
];



@Component({
  selector: 'app-personal',
  templateUrl: './personal.component.html',
  styles: [],
  styleUrls: ['./personal.component.css'],
  providers: [
    {
        provide: DateAdapter, useClass: AppDateAdapter
    },
    {
        provide: MAT_DATE_FORMATS, useValue: APP_DATE_FORMATS
    }
    ]
})

export class PersonalComponent implements OnInit {

  //displayedColumns: string[] = ['position', 'name', 'weight', 'symbol'];
  dataSource = new MatTableDataSource(ELEMENT_DATA);

  @ViewChild(MatSort, {static: true}) sort: MatSort;

  
  usuarios: Usuario[] = [];
  usuariosExport: Usuario[] = [];
  usuariosFilter: Usuario[] = [];
  loading: boolean;
  excel: boolean;
  error: any;
  user: boolean;
  datos: any;
  today: Date;
  date: string;
  horas: Hora[];
  patente: Patente[];
  use: FormGroup;
  selectedEmpresa: any;
  empresas: Empresas[] = [];

  hours: Hour[] = [];
  origin:  ['Campanario'];
  destinity: ['Campanario'];

  toppings = new FormControl();

  displayedColumnsUsuarios = ['rut','nombre','conductor','fecha','hora','origen','destino','horaIng','empresa','star'];
  dataSourceTable = new MatTableDataSource<UsuarioInt>();
  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;

  limpiarForm: FormGroup = new FormGroup({
    fecha: new FormControl('', Validators.required),
    fechahasta: new FormControl('', Validators.required),
    hora: new FormControl('', Validators.required),
    patente: new FormControl('', Validators.required),
    fechaActual: new FormControl(''),
    fechaActual2: new FormControl(''),
    empresa: new FormControl('', Validators.required),
    rut: new FormControl('', Validators.required ),
  });
  selectedHora: any;
  selectedPatente: any;
  selectedFecha: any;
  selectedFechaHasta: any
  selectedRut: any

  login: string;
  deviceInfo = null;
  isDesktop: boolean;
  fechaCheckActual = false;

  constructor( 
    public datepipe: DatePipe,
    private store: Store<AppState>, 
    private router: Router,
    private crd: ChangeDetectorRef,
    private excelService:ExporterService,
    private usuarioService: UsuarioService,
    private deviceService: DeviceDetectorService ) { 
      this.deviceInfo = this.deviceService.getDeviceInfo();
      this.isDesktop = this.deviceService.isDesktop();
    }

  ngOnInit() {

    this.login = localStorage.getItem('login')
    this.dataSource.sort = this.sort;
    this.loading=false;
    this.user=false;
    this.excel= false;

    this.today =new Date();
    

    this.store.select('usuarios')
        .subscribe( usuarios => {
          this.usuarioService.getHora()
          this.usuarios = usuarios.users;
          this.loading = false;
          this.error = usuarios.error;

        });
    this.getHora();
    this.getPatente();
    this.getEmpresa();
    this.getOnlyEmpresa();
    this.store.dispatch( new usuariosActions.CargarUsuarios() );

   console.log("this.loading",this.loading) 
  }

 
  filterHours(){
    if(this.toppings.value != 0){
      this.usuariosFilter = [];
      this.toppings.value.forEach(element => {

        this.usuarios.forEach( elementUsu =>{

          // if(elementUsu.HORA.substr(-5,2) == element.valor ){
            if(elementUsu.HORA == element.HORA ){
            this.usuariosFilter.push(elementUsu);
            }
        });
        this.dataSourceTable.data = this.usuariosFilter;
        this.usuariosExport = this.usuariosFilter;
      });
    }else{
      //Si no hay nada seleccionado
      this.dataSourceTable.data = this.usuarios;
      this.usuariosExport = this.usuarios;
    }

  }

  filterOrigen(){
    console.log("this.hours",this.toppings.value)
    if(this.toppings.value != 0){
      this.usuariosFilter = [];
      this.toppings.value.forEach(element => {
        this.usuarios.forEach( elementUsu =>{
          // if(elementUsu.HORA.substr(-5,2) == element.valor ){
            if(elementUsu.ORIGEN == 'Chanavayita' ){
            this.usuariosFilter.push(elementUsu);
            }
        });
        this.dataSourceTable.data = this.usuariosFilter;
        this.usuariosExport = this.usuariosFilter;
      });
    }else{
      //Si no hay nada seleccionado
      this.dataSourceTable.data = this.usuarios;
      this.usuariosExport = this.usuarios;
    }

  }

  filterDestino(){
    if(this.toppings.value != 0){
      this.usuariosFilter = [];
      this.toppings.value.forEach(element => {
        this.usuarios.forEach( elementUsu =>{
          // if(elementUsu.HORA.substr(-5,2) == element.valor ){
            if(elementUsu.DESTINO == 'Campanario' ){
            this.usuariosFilter.push(elementUsu);
            }
        });
        this.dataSourceTable.data = this.usuariosFilter;
        this.usuariosExport = this.usuariosFilter;
      });
    }else{
      //Si no hay nada seleccionado
      this.dataSourceTable.data = this.usuarios;
      this.usuariosExport = this.usuarios;
    }

  }


  getHora(){
    this.usuarioService.getHora().subscribe( resp => {
      this.horas = resp;
      this.hours = resp;
      console.log("this.hous",this.hours)
    });
    this.crd.detectChanges();
  }

  getOnlyEmpresa(){
    this.usuarioService.getOnlyEmpresa()
    .subscribe( response =>{
      this.empresas = response;
      console.log("Get Empresa",this.empresas)
      this.crd.detectChanges();
    });
  }

  
    getEmpresa(){
    this.origin = ["Campanario"];
    this.destinity = ["Campanario"];
    
    // this.usuarioService.getEmpresa().subscribe( resp => {
    //    this.origin = resp;
    //   console.log("Get Empresa",this.origin)
    // });
    // this.crd.detectChanges();
  }

  

  irUsuario( fecha: string, fechaFin: string, hora: string, patente: string, selectedRut: string, selectedEmpresa: string ) {

    this.loading = true;
    this.excel = false;
    this.user = false;
    const date =this.datepipe.transform(fecha, 'dd-MM-yyyy');
    const dateFin =this.datepipe.transform(fechaFin, 'dd-MM-yyyy');

    if (selectedRut == null || selectedRut == undefined)
    selectedRut = '';

    if (selectedEmpresa==null|| selectedRut == undefined) 
    selectedEmpresa ='';
      console.log("rut",selectedRut);

    if(fecha !== undefined && fechaFin != undefined){
      if(fecha!=null && fechaFin!=null){
        if (hora === undefined && patente === undefined){
        hora = '';
        patente = '';
        console.log("empresa",selectedEmpresa);
        this.usuarioService.getDatos(date,dateFin,hora,patente,selectedRut,selectedEmpresa)
        .subscribe( resp => {
        this.usuarios = resp;
        this.dataSourceTable.data = this.usuarios;
        if(this.usuarios.length != 0){
        this.loading = false;
        this.user = false;
        this.excel = true;
        this.usuariosExport = this.usuarios;
        }
        else{
          this.user = true;
          this.loading = false;
          this.excel = false;
  
          }
        });
        }
        if(patente === null && hora === null){
          hora = '';
          patente = '';
          
          this.usuarioService.getDatos(date,dateFin,hora,patente,selectedRut,selectedEmpresa)
          .subscribe( resp => {
            this.usuarios = resp;
            if(this.usuarios.length != 0){
            this.loading = false;
            this.user = false;
            this.excel = true;
            }
            else{
            this.user = true;
            this.loading = false;
            this.excel = false;

    
            }
          });
          this.crd.detectChanges();
        }
        if(hora !== undefined && patente === undefined){
          patente = '';
          this.usuarioService.getDatos(date,dateFin,hora,patente,selectedRut,selectedEmpresa)
          .subscribe( resp => {
          this.usuarios = resp;
          if(this.usuarios.length != 0){
          this.loading = false;
          this.user = false;
          this.excel = true;
          }
          else{
            this.user = true;
            this.loading = false;
            this.excel = false;
    
            }
          });
        }
        if(hora !== null && patente === null){
          patente = '';
          this.usuarioService.getDatos(date,dateFin,hora,patente,selectedRut,selectedEmpresa)
          .subscribe( resp => {
          this.usuarios = resp;
          if(this.usuarios.length != 0){
          this.loading = false;
          this.user = false;
          this.excel = true;
          }
          else{
            this.user = true;
            this.loading = false;
            this.excel = false;
    
            }
          });
        }
        if(hora === undefined && patente !== undefined){
          hora = '';
          this.usuarioService.getDatos(date,dateFin,hora,patente,selectedRut,selectedEmpresa)
          .subscribe( resp => {
          this.usuarios = resp;
          if(this.usuarios.length != 0){
          this.loading = false;
          this.user = false;
          this.excel = true;
          }
          else{
            this.user = true;
            this.loading = false;
            this.excel = false;
    
            }
          });
        }
        if(hora === null && patente !== null){
          hora = '';
          this.usuarioService.getDatos(date,dateFin,hora,patente,selectedRut,selectedEmpresa)
          .subscribe( resp => {
          this.usuarios = resp;
          if(this.usuarios.length != 0){
          this.loading = false;
          this.user = false;
          this.excel = true;
          }
          else{
            this.user = true;
            this.loading = false;
            this.excel = false;
    
            }
          });
        }
        if(hora !== "" && patente !== ""){
          this.usuarioService.getDatos(date,dateFin,hora,patente,selectedRut,selectedEmpresa)
          .subscribe( resp => {
          this.usuarios = resp;
          if(this.usuarios.length != 0){
          this.loading = false;
          this.user = false;
          this.excel = true;
          }
          else{
            this.user = true;
            this.loading = false;
            this.excel = false;
    
            }
          });
         
        }



    this.crd.detectChanges();
    }
    else
  {this.loading = false;}
  }
  else
  {this.loading = false;}

  this.toppings.reset();
  }

  limpiar(){
    this.crd.markForCheck();
    this.loading = false;
    this.excel = false;
    this.limpiarForm.reset();
    this.user = false;
    this.limpiarForm.controls['fecha'].enable();

  }


  getPatente(){
    this.usuarioService.getPatente().subscribe( resp => {
      this.patente = resp;
    });
    this.crd.detectChanges();
  }

  exportAsXLSX():void {

    if(this.usuariosExport.length != 0){
    this.excelService.exportAsExcelFile(this.usuariosExport, 'home');
  } else{
    Swal.fire({
      icon: 'error',
      title: 'Alerta',
      text: 'No se encuentran datos a exportar!',
    })
  }

  }

  descargarPDF(){
    // console.log("LISTA PDF",this.usuarios)
    // var elementHandler = {
    //   '#ignorePDF': function (element, renderer) {
    //   return true;
    //   }}
    // const doc = new jsPDF();
    // doc.fromHTML(document.getElementById('limpiarForm'),10,10);
    // doc.fromHTML(
    //   document.getElementById('limpiarForm'),
    //   10,
    //   10,
    //   {
    //   'width': 280,'elementHandlers': elementHandler
    //   });
    // doc.save('Lista de Usuarios')
  }
  
  capturarContenido(){
    // console.log("ASDASD");
    
    // var data = document.getElementById('limpiarForm');
    // html2canvas(data).then(canvas => {
    //   console.log("canvas",canvas);
      
    //   var imgWidth = 208;
    //   var imgHeight = canvas.height * imgWidth / canvas.width;
    //   let pdf = new jsPDF('p','mm','a4');
    //   var position = 0;
    //   pdf.save('ListaPDF.pdf');
    // });
    // console.log("LISTA PDF",this.usuarios)
    // var elementHandler = {
    //   '#ignorePDF': function (element, renderer) {
    //   return true;
    //   }}
    // const doc = new jsPDF('p','mm','a4');
    // doc.fromHTML(
    //   document.getElementById('limpiarForm'),
    //   10,
    //   10,
    //   {
    //   'width': 280,'elementHandlers': elementHandler
    //   });
    // doc.save('Lista de Usuarios')
  }
  fechaActual(){
    this.fechaCheckActual = this.limpiarForm.controls['fechaActual'].value;
    console.log('fechaCheckActual', this.fechaCheckActual);

    if(this.selectedFecha === undefined || this.selectedFecha === null ){
      this.fechaCheckActual = false;
    }
    if(this.fechaCheckActual === false ){
      this.limpiarForm.controls['fecha'].disable();
      this.selectedFecha = this.today;
      console.log('if today', this.selectedFecha);
      // this.datosCasino( this.selectedFechaDesde,this.selectedFechaHasta);
    } else{
      this.limpiarForm.controls['fecha'].enable();
      console.log('else today', this.selectedFecha);
    }
    this.crd.detectChanges();
  }

  fechaActual2(){
    this.fechaCheckActual = this.limpiarForm.controls['fechaActual2'].value;
    console.log('fechaCheckActual', this.fechaCheckActual);

    if(this.selectedFechaHasta === undefined || this.selectedFechaHasta === null ){
      this.fechaCheckActual = false;
    }
    if(this.fechaCheckActual === false ){
      this.limpiarForm.controls['fechahasta'].disable();
      this.selectedFechaHasta = this.today;
      console.log('if today', this.selectedFechaHasta);
      // this.datosCasino( this.selectedFechaDesde,this.selectedFechaHasta);
    } else{
      this.limpiarForm.controls['fechahasta'].enable();
      console.log('else today', this.selectedFechaHasta);
    }
    this.crd.detectChanges();
  }

}

export interface UsuarioInt{
  //CODIGO_QR: number;
  DESTINO: string;
  FECHA: string;
  HORA: string;
  NOMBRE: string;
  CONDUCTOR: string;
  RUT: string;
  PATENTE: string;
  ORIGEN: string;
  HORA_INGRESO: string;
  EMPRESA: string;
}

export interface Hour{
  HORA: string;
}

export interface Origen{
  ORIGEN: string;
}

export interface PeriodicElement {
  name: string;
  position: number;
  weight: number;
  symbol: string;
}