import { NgModule } from '@angular/core';

import { Routes, RouterModule } from '@angular/router';
import { PersonalComponent } from './usuarios/personal/personal.component';
import { UsuarioComponent } from './usuarios/ingreso-personal/ingreso-personal.component';
import { LoginComponent } from './auth/login/login.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { dashboardRoutes } from './dashboard/dashboard.routes';
import { loginRoutes } from './auth/login/login.routes';
import { ExporterService } from './services/exporter.service';



const routes: Routes = [
  { path: '', redirectTo: 'login', pathMatch: 'full' },

  // { path: 'login', component: LoginComponent },
  // { path: 'personal', component: UsuarioComponent },
  { path: 'login', component: LoginComponent },
  // { path: 'personal', component: UsuarioComponent },
  // { path: 'home', component: PersonalComponent },
  {
    path: '',
    component: DashboardComponent,
    children: dashboardRoutes,
    // canActivate: [ DashboardComponent ],
  },
  { path: '**', redirectTo: 'login' }
  ];



@NgModule({
  imports: [
    RouterModule.forRoot( routes )
  ],
  exports: [
    RouterModule
  ]
})
export class AppRoutingModule { }
