export class Resume {
    profilePic: string;
    // codigo: string;
    // nombre: string;
    // empresa: string;
    // address: string;
    // contactNo: number;
    // email: string;
    // fecha: string;
    // rut: string;
    // otro: string;
     NOMBRE: string;
     EMPRESA: string;
     RUT: string;
     CODIGO: string;
     ESTADO: string;
     OTRO: string;

    socialProfile: string;
    experiences: Personal[] = [];
    educations: Education[] = [];
    otherDetails: string;
    skills: Skill[] = [];

    constructor() {
        // this.experiences.push(new Personal());
        // this.educations.push(new Education());
        // this.skills.push(new Skill());
    }
}

export class Personal {
    codigo: string;
    rut: string;
    nombre: string;
    fecha: string;
    empresa: string;

    //  CODIGO: number,
    //  RUT: string,
    //  NOMBRE: string,
    //  FECHA: string,
    //  HORA_INGRESO: string,
    //  EMPRESA: string,
    //  CENA_NOCHE: string,
    //  DESAYUNO: string,
    //  ALMUERZO: string,
    //  CENA: string,
    //  NUMERO: string,
    //  LOCALIZACION: string,
}

export class Education {
    degree: string;
    college: string;
    passingYear: string;
    percentage: number;
}

export class Skill {
    value: string;
}