<div class="row">
    <div class="col-12">
        <div class="card">
            <div class="card-body">
                <!-- <h4 class="card-title">Agregar Costo y Venta</h4> -->
                <!-- <p class="card-description">
                    Especifique el monto, codigo, descripcion y el tipo
                </p> -->

                <form [formGroup]="forma" *ngFor="let item of cod">

                    <!-- <div class="form-group">
                        <label>Fecha</label>
                        <input type="date" class="form-control" formControlName="fecha" name="fecha" />
                    </div> -->
                    <h4 class="card-title d-flex align-items-center">
                        <i class="material-icons">
                          account_circle
                        </i> Ingreso Personal</h4>
                    <br>
                    <div class="form-row">
                        <div class="form-group col-md-6">
                            <div class="form-group">
                                <label>Codigo QR</label>
                                <input type="text" #txtCodigo class="form-control" placeholder="Codigo" formControlName="codigo" [(ngModel)]="item.CODIGO" name="codigo">
                                <button (click)="buscarCodigoQR(txtCodigo.value)" type="button" class="btn btn-dark btn-block">Buscar Codigo QR</button>
                            </div>
                            <div class="form-group">
                                <label>Nombre Completo:</label>
                                <input type="text" #txtNombre class="form-control" placeholder="Nombre" formControlName="nombre" [(ngModel)]="item.NOMBRE" name="nombre">
                            </div>
                            <div class="form-group">
                                <label for="inputPassword4">EMPRESA</label>
                                <mat-form-field class="form-control">
                                    <mat-label>Seleccione: </mat-label>
                                    <mat-select [(ngModel)]="item.EMPRESA" value="{{ item.EMPRESA }}" #txtEmpresa formControlName="empresa">
                                        <mat-option *ngFor="let empresa of empresas" value="{{ empresa.Codigo }}">{{ empresa.Empresa }}</mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </div>
                        </div>
                        <div class="form-group col-md-6">
                            <div class="form-group">
                                <label>Rut:</label>
                                <input type="text" #txtRut class="form-control" placeholder="Rut" formControlName="rut" [(ngModel)]="item.RUT" name="rut">
                                <button (click)="buscarRut(txtRut.value)" type="button" class="btn btn-dark btn-block">Buscar Rut</button>
                            </div>
                            <div class="form-group">
                                <label>Descripcion:</label>
                                <input type="text" #txtDescripcion class="form-control" placeholder="Descripcion" formControlName="descripcion" [(ngModel)]="item.OTRO" name="descripcion">
                            </div>
                            <div class="form-group">
                                <label>Estado</label>
                                <div class="form-group col-md-7" #txtEstado>
                                    <button (click)="tipo='activo'" *ngIf="tipo ==='inactivo'" type="button" class="btn btn-success btn-block">Activo</button>
                                    <button (click)="tipo='inactivo'" *ngIf="tipo ==='activo'" type="button" class="btn btn-danger btn-block">Inactivo</button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="card-body">
                    </div>
                    <hr>
                    <div class="card-body">
                        <div class="form-row">
                            <div class="form-group col-md-4">
                                <button (click)="crearIngreso(item.CODIGO,item.RUT,item.NOMBRE,item.EMPRESA,item.OTRO, txtEstado.value)" [disabled]="forma.invalid" type="submit" class="btn btn-success mr-2">
                    <i class="fa fa-save"></i>
                    Guardarlo
                    </button>
                            </div>
                            <div class="form-group col-md-4">

                                <button (click)="limpiar()" type="button" class="btn btn-warning mr-2">
                    <i class="fas fa-eraser"></i>                   
                     Limpiar
                    </button>
                                <!-- <button (click)="generatePdf('open')" class="btn btn-primary d-flex align-items-center justify-content-center">
                        <i class="material-icons">
                          picture_as_pdf
                        </i> <span>Abrir PDF</span></button>
                                <button (click)="generatePdf('download')" class="btn btn-primary d-flex align-items-center justify-content-center">
                        <i class="material-icons">
                          cloud_download
                        </i><span>Descargar PDF</span></button> -->
                            </div>
                            <div class="form-group col-md-4">

                                <!-- <button *ngIf="cargando" disabled class="btn btn-success mr-2">
                    <i class="fa fa-spin fa-sync"></i>
                    Espere por favor...
                    </button> -->

                                <button type="button" (click)="back()" class="btn btn-light">
                    <i class="fas fa-backward"></i>
                    Volver</button>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>