import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { AppState } from '../../store/app.reducer';

import * as usuarioActions from '../../store/actions';
import { Usuario } from '../../models/usuario.model';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { UsuarioService } from '../../services/usuario.service';
import { IngresoPersonal } from 'src/app/models/Ingreso.model';
import Swal from 'sweetalert2';
import { Codigo } from '../../models/codigo.model';
import { Empresas } from '../../models/empresa.model';
import { DateAdapter, MAT_DATE_FORMATS, MatTableDataSource } from '@angular/material';
import { AppDateAdapter, APP_DATE_FORMATS } from '../date.adapter';
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import { ScriptService } from '../../services/script.service';
import { Personal, Resume, Education, Skill } from '../resme';
import { DatePipe } from '@angular/common';
import { Ubicacion } from '../../models/ubicacion.model';
pdfMake.vfs = pdfFonts.pdfMake.vfs;

@Component({
  selector: 'app-ubicacion',
  templateUrl: './ubicacion.component.html',
  styles: [],
  providers: [
    {
        provide: DateAdapter, useClass: AppDateAdapter
    },
    {
        provide: MAT_DATE_FORMATS, useValue: APP_DATE_FORMATS
    }
    ]
})
export class UbicacionComponent implements OnInit {

  resume = new Resume();

  degrees = ['B.E.', 'M.E.', 'B.Com', 'M.Com'];

  dd = new Date();
  usuarios: Usuario[] = [];
  codigo: Codigo = new Codigo();
  codigoQ: Codigo[] = [];
  empresas: Object;
  user: Usuario;
  loading: boolean;
  error: any;
  Empresa: any;
  cod: Object;
  code;
  selected: Empresas["Codigo"];
  login: string;
  selectCodigo: any;
  selectUbicacion: any;
  selectedFecha: any;
  selectedHora: any;
  selectedTipo: any;
    //Table
    displayedColumns: string[] = ['codigo','rut','nombre','fecha','horaIng','empresa','cenaNoc','desay','almuer','cena','local'];
    dataSource = new MatTableDataSource<Ubicacion>();

  
  tipo: any = [{'TIPO': 'Desayuno'},{'TIPO': 'Almuerzo'},{'TIPO': 'Cena'},{'TIPO': 'Cena noche'}];
  ubicacion: any = [{'UBICACION': 'Campanario'},{'UBICACION': 'Lo Aguirre'},{'UBICACION': 'Pique de Válvula'}];
  fechaCheckActual = false;
  today: Date;

  constructor( public datepipe: DatePipe,
               private scriptService: ScriptService,
               private router: ActivatedRoute,
               private usuarioService: UsuarioService,
               private route: Router,
               private crd: ChangeDetectorRef,
               private store: Store<AppState>) { 
                this.resume = JSON.parse(sessionStorage.getItem('codigo')) || new Resume();
                // if (!this.resume.experiences || this.resume.experiences.length === 0) {
                //   this.resume.experiences = [];
                //   this.resume.experiences.push(new Personal());
                // }
                // if (!this.resume.educations || this.resume.educations.length === 0) {
                //   this.resume.educations = [];
                //   this.resume.educations.push(new Education());
                // }
                // if (!this.resume.skills || this.resume.skills.length === 0) {
                //   this.resume.skills = [];
                //   this.resume.skills.push(new Skill());
                // }
            
                console.log('Loading External Scripts');
                this.scriptService.load('pdfMake', 'vfsFonts');
                }

  forma: FormGroup = new FormGroup({
    codigo: new FormControl('', Validators.required),
    fecha: new FormControl('', Validators.required),
    ubicacion: new FormControl('', Validators.required),
    tipo: new FormControl('', Validators.required),
    fechaActual: new FormControl('')
  });

  ngOnInit() {
    
    this.today =new Date();
    this.login = localStorage.getItem('login')
    this.getEmpresa()
    this.cod = [{}];
    this.router.params
      .subscribe( params => {
        const id = params['id'];
        this.store.dispatch( new usuarioActions.CargarUsuario(id) );
      });

    this.store.select('usuario')
        .subscribe( usuario => {
          this.user = usuario.user;
          this.loading = usuario.loading;
          this.error = usuario.error;

        });
  }

  generatPdf(){
    const documentDefinition = { content: 'PDF' };
    pdfMake.createPdf(documentDefinition).open({}, window);
    console.log(pdfMake.createPdf(documentDefinition).open())
   }

  getEmpresa(){
    this.usuarioService.getEmpresa().subscribe( resp => {
      this.empresas = resp; 
    });

  }

  getClassEstado(eat: string){
    let cssClasses;
    if(eat == '1'){
      cssClasses = {'fa fa-check':true, 'fas fa-times':false}
    }else{
      cssClasses = {'fa fa-check':false, 'fas fa-times':true}
    }
    return cssClasses;
  }

  getEstado(estado: string){
    let cssClasses;
    if(estado == '1'){
      cssClasses = {'fa fa-check':true, 'fas fa-times':false}
    }else{
      cssClasses = {'fa fa-check':false, 'fas fa-times':true}
    }
    return cssClasses;
  }



  back(){
    this.route.navigate(['/home']);

  }


  limpiar(){
    this.forma.reset({ monto: 0 });
  }





  crearIngreso(codigo: string, ubicacion: any, fecha: any,tip: any){

    if(tip === 'Desayuno'){
      tip = '1';
    }
    if(tip === 'Almuerzo'){
      tip = '2';
    }
    if(tip === 'Cena'){
      tip = '3';
    }
    if(tip === 'Cena noche'){
      tip = '4';
    }
    if(ubicacion === 'Campanario'){
      ubicacion = '1';
    }
    if(ubicacion === 'Lo Aguirre'){
      ubicacion = '2';
    }
    if(ubicacion === 'Pique de Válvula'){
      ubicacion = '3';
    }



    const fechaDay = this.dd.getDate();

    const date =this.datepipe.transform(fecha, 'dd-MM-yyyy');
    let estado = '';
    let nombre = '';
    let tipo = '';
    this.usuarioService.getUbicacion(codigo,ubicacion,date,tip)
    .subscribe( resp => {
      for (const a of resp){
        estado = a.Estado;
        tipo = a.Tipo;
        nombre = a.Nombre;
      }
      // Swal.fire({
      //   title: 'Espere',
      //   text: 'Guardando información',
      //   type: 'info',
      //   allowOutsideClick: false
      // });

      if(estado !== '0'){
        Swal.fire({
          icon: 'success',
          title: tipo,
          text: nombre,
        })
        this.forma.reset({ monto: 0 });
      }
      else{
        Swal.fire({
        icon: 'error',
        title: tipo,
        text: nombre,
      })
      this.forma.reset({ monto: 0 });
      }




    });

  }


  // addExperience() {
  //   this.resume.experiences.push(new Personal());
  // }

  // addEducation() {
  //   this.resume.educations.push(new Education());
  // }

  generatePdf(action = 'open') {
    const documentDefinition = this.getDocumentDefinition();

    switch (action) {
      case 'open': pdfMake.createPdf(documentDefinition).open(); break;
      case 'print': pdfMake.createPdf(documentDefinition).print(); break;
      case 'download': pdfMake.createPdf(documentDefinition).download(); break;

      default: pdfMake.createPdf(documentDefinition).open(); break;
    }

  }


  resetForm() {
    this.resume = new Resume();
  }

  getDocumentDefinition() {
    sessionStorage.setItem('codigo', JSON.stringify(this.resume));

    return {
      content: [
        {
          text: 'RESUME',
          bold: true,
          fontSize: 20,
          alignment: 'center',
          margin: [0, 0, 0, 20]
        },
        {
          columns: [
            [{
              text: this.resume.CODIGO,
              style: 'name'
            },
            {
              text: this.resume.NOMBRE
            },
            {
              text: 'Rut : ' + this.resume.RUT,
            },
            {
              text: 'Empresa : ' + this.resume.EMPRESA,
            },
            {
              text: 'Estado : ' + this.resume.OTRO,
            },
            {
              text: 'GitHub: ' + this.resume.socialProfile,
              link: this.resume.socialProfile,
              color: 'blue',
            }
            ],
            [
              this.getProfilePicObject()
            ]
          ]
        },
        {
          columns : [
              { qr: this.resume.CODIGO + ', Nombre : ' + this.resume.NOMBRE, fit : 100 },
              {
              text: `(${this.resume.CODIGO})`,
              alignment: 'right',
              }
          ]
        }
      ],
      info: {
        title: this.resume.CODIGO + '_RESUME',
        author: this.resume.CODIGO,
        subject: 'RESUME',
        keywords: 'RESUME, ONLINE RESUME',
      },
        styles: {
          header: {
            fontSize: 18,
            bold: true,
            margin: [0, 20, 0, 10],
            decoration: 'underline'
          },
          name: {
            fontSize: 16,
            bold: true
          },
          jobTitle: {
            fontSize: 14,
            bold: true,
            italics: true
          },
          sign: {
            margin: [0, 50, 0, 10],
            alignment: 'right',
            italics: true
          },
          tableHeader: {
            bold: true,
          }
        }
    };
  }

  getExperienceObject(experiences: Personal[]) {

    const exs = [];

    experiences.forEach(experience => {
      exs.push(
        [{
          columns: [
            [{
              text: experience.codigo,
              style: 'codigo'
            },
            {
              text: experience.rut,
            },
            {
              text: experience.nombre,
            }],
            {
              text: 'Experience : ' + experience.empresa + ' Months',
              alignment: 'right'
            }
          ]
        }]
      );
    });

    return {
      table: {
        widths: ['*'],
        body: [
          ...exs
        ]
      }
    };
  }

  getEducationObject(educations: Education[]) {
    return {
      table: {
        widths: ['*', '*', '*', '*'],
        body: [
          [{
            text: 'Degree',
            style: 'tableHeader'
          },
          {
            text: 'College',
            style: 'tableHeader'
          },
          {
            text: 'Passing Year',
            style: 'tableHeader'
          },
          {
            text: 'Result',
            style: 'tableHeader'
          },
          ],
          ...educations.map(ed => {
            return [ed.degree, ed.college, ed.passingYear, ed.percentage];
          })
        ]
      }
    };
  }

  getProfilePicObject() {
    if (this.resume.profilePic) {
      return {
        image: this.resume.profilePic ,
        width: 75,
        alignment : 'right'
      };
    }
    return null;
  }

  fileChanged(e) {
    const file = e.target.files[0];
    this.getBase64(file);
  }

  getBase64(file) {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      this.resume.profilePic = reader.result as string;
    };
    reader.onerror = (error) => {
      console.log('Error: ', error);
    };
  }

  fechaActual(){
    this.fechaCheckActual = this.forma.controls['fechaActual'].value;
    if(this.selectedFecha === undefined || this.selectedFecha === null ){
      this.fechaCheckActual = false;
    }
    if(this.fechaCheckActual === false ){
      this.forma.controls['fecha'].disable();
      this.selectedFecha = this.today;
    } else{
      this.forma.controls['fecha'].enable();
    }
    this.crd.detectChanges();
  }

  // addSkill() {
  //   this.resume.skills.push(new Skill());
  // }


}
