<div class="row">
    <div class="col-12">
        <div class="card">
            <div class="card-body">
                <!-- <h4 class="card-title">Agregar Costo y Venta</h4> -->
                <!-- <p class="card-description">
                    Especifique el monto, codigo, descripcion y el tipo
                </p> -->

                <form [formGroup]="forma">

                    <!-- <div class="form-group">
                        <label>Fecha</label>
                        <input type="date" class="form-control" formControlName="fecha" name="fecha" />
                    </div> -->
                    <h4 class="card-title d-flex align-items-center">
                        <i class="material-icons">
                          account_circle
                        </i> Ticket</h4>
                    <br>
                    <div class="form-row">
                        <div class="form-group col-md-6">
                            <label>Codigo QR</label>
                            <input type="text" #txtCodigo class="form-control" placeholder="Codigo" formControlName="codigo" [(ngModel)]="selectCodigo" name="codigo">
                            <!-- <button (click)="buscarCodigoQR(txtCodigo.value)" type="button" class="btn btn-dark btn-block">Buscar Codigo QR</button> -->
                        </div>
                        <div class="form-group col-md-6">
                            <label for="inputPassword4">Ubicacion</label>
                            <mat-form-field class="form-control">
                                <mat-label>Seleccione </mat-label>
                                <mat-select [(ngModel)]="selectUbicacion" #txtInput2 formControlName="ubicacion">
                                    <mat-option *ngFor="let item of ubicacion" value="{{ item.UBICACION }}">{{ item.UBICACION }}</mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                        <div class="form-group col-md-6">
                            <label for="inputEmail4">FECHA</label>
                            <mat-form-field class="form-control">
                                <input [(ngModel)]="selectedFecha" #txtInput1 matInput [matDatepicker]="picker" formControlName="fecha" placeholder="Fecha">
                                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                                <mat-datepicker #picker></mat-datepicker>
                            </mat-form-field>
                            <mat-checkbox (click)="fechaActual()" formControlName="fechaActual">Fecha actual</mat-checkbox>
                        </div>
                        <div class="form-group col-md-6">
                            <label for="inputPassword4">TIPO</label>
                            <mat-form-field class="form-control">
                                <mat-label>Seleccione </mat-label>
                                <mat-select [(ngModel)]="selectedTipo" #txtInput2 formControlName="tipo">
                                    <mat-option *ngFor="let tipos of tipo" value="{{ tipos.TIPO }}">{{ tipos.TIPO }}</mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                    </div>
                    <div class="card-body">
                    </div>
                    <hr>
                    <div class="card-body">
                        <div class="form-row">
                            <div class="form-group col-md-4">
                                <button (click)="crearIngreso(selectCodigo,selectUbicacion,selectedFecha,selectedTipo)" [disabled]="forma.invalid" type="submit" class="btn btn-success mr-2">
                    <i class="fa fa-save"></i>
                    Insertar
                    </button>
                            </div>
                            <div class="form-group col-md-4">

                                <button (click)="limpiar()" type="button" class="btn btn-warning mr-2">
                    <i class="fas fa-eraser"></i>                   
                     Limpiar
                    </button>
                                <!-- <button (click)="generatePdf('open')" class="btn btn-primary d-flex align-items-center justify-content-center">
                        <i class="material-icons">
                          picture_as_pdf
                        </i> <span>Abrir PDF</span></button>
                                <button (click)="generatePdf('download')" class="btn btn-primary d-flex align-items-center justify-content-center">
                        <i class="material-icons">
                          cloud_download
                        </i><span>Descargar PDF</span></button> -->
                            </div>
                            <div class="form-group col-md-4">

                                <!-- <button *ngIf="cargando" disabled class="btn btn-success mr-2">
                    <i class="fa fa-spin fa-sync"></i>
                    Espere por favor...
                    </button> -->

                                <button type="button" (click)="back()" class="btn btn-light">
                    <i class="fas fa-backward"></i>
                    Volver</button>
                            </div>
                        </div>
                    </div>
                </form>
            </div>


        </div>
    </div>
</div>