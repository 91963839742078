<br><br>
<form novalidate [formGroup]="limpiarForm">

    <div class="form-row">

    
        <div class="form-group col-md-3">
            <label for="inputPassword4">DESDE</label>
            <mat-form-field class="form-control">
                <input [(ngModel)]="selectedFechaDesde" matInput [matDatepicker]="calendar1" formControlName="fechaDesde" placeholder="Fecha">
                <mat-datepicker-toggle matSuffix [for]="calendar1"></mat-datepicker-toggle>
                <mat-datepicker #calendar1></mat-datepicker>
            </mat-form-field>
            <mat-checkbox (click)="fechaActualDesde()" formControlName="fechaActualDesde">Fecha actual</mat-checkbox>
        </div>
        <div class="form-group col-md-3">
            <label for="inputPassword4">HASTA</label>
            <mat-form-field class="form-control">
                <input [(ngModel)]="selectedFechaHasta" matInput [matDatepicker]="calendar2" formControlName="fechaHasta" placeholder="Fecha">
                <mat-datepicker-toggle matSuffix [for]="calendar2"></mat-datepicker-toggle>
                <mat-datepicker #calendar2></mat-datepicker>
            </mat-form-field>
            <mat-checkbox (click)="fechaActual()" formControlName="fechaActual">Fecha actual</mat-checkbox>
        </div>
        <div class="form-group col-md-3">
            <label for="inputPassword4">EMPRESA</label>
            <mat-form-field class="form-control">
                <mat-label>Seleccione Empresa</mat-label>
                <mat-select [(ngModel)]="selectedEmpresa" #txtInput2 formControlName="empresa">
                    <mat-option *ngFor="let empresa of empresas" value="{{ empresa.Codigo }}">{{ empresa.Empresa }}</mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        <div class="form-group col-md-2">
            <label>Rut</label>
            <input type="text" #txtCodigo class="form-control" placeholder="Rut" formControlName="rut" [(ngModel)]="selectedRut" name="rut">
        </div>
        <div class="form-group col-md-1">
            <div>
                <div class="form-inline my-2 my-lg-2">
                    <button mat-raised-button (click)="datosCasino( selectedFechaDesde,selectedFechaHasta,selectedRut,selectedEmpresa)" class="btn btn-login my-2 my-sm-0" type="submit">Buscar</button>
                </div>
                <div class="form-inline my-2 my-lg-2">
                    <button mat-raised-button (click)="limpiar()" class="btn btn-outline-warning my-2 my-sm-0 " type="button ">Limpiar</button>
                </div>
                <div class="form-inline my-2 my-lg-2">
                    <button mat-raised-button routerLink="/ubicacion" class="btn btn-outline-warning my-2 my-sm-0 " type="button ">Insertar</button>
                </div>
            </div>
        </div>
    </div>

</form>

<br>
<div class="container" *ngIf="excel===true">
    <button mat-raised-button (click)="exportAsXLSX()" class="btn btn-outline-info my-2 my-sm-0" type="submit">Exportar Excel
        <i class="fas fa-file-export"></i>
    </button>

    <div class="mat-elevation-z8">
        
        <!-- AG - GRID -->
        <ag-grid-angular 
        style="width: 100%; height: 500px;"
        class="ag-theme-balham"
        [rowData]="rowData"
        [columnDefs]="columnDefs">
        </ag-grid-angular>


        <!-- <mat-paginator [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons></mat-paginator> -->
    </div>

</div>

<div class="row " *ngIf="loading===true ">
    <div class="col ">
        <div class="alert alert-info text-center ">
            <i class="fa fa-sync fa-spin fa-2x "></i>
            <h1>Buscando datos...</h1>
        </div>
    </div>
</div>
<div class="row " *ngIf="user===true ">
    <div class="col ">
        <div class="alert alert-info text-center ">
            <!-- <i class="fa fa-sync fa-spin fa-2x "></i> -->
            <h1>No se encontraron datos...</h1>
        </div>
    </div>
</div>

<div class="row " *ngIf="error ">
    <div class="col ">
        <div class="alert alert-danger text-center ">
            <i class="fa fa-times fa-2x "></i>
            <h2>Error: {{ error.status }} </h2>
            <p>
                {{ error.message }}
                <br><br> {{ error.url }}
            </p>
        </div>
    </div>
</div>