<app-navbar></app-navbar>


<div class="page-body-wrapper">



    <div class="main-panel">

        <div class="content-wrapper">

            <router-outlet></router-outlet>

        </div>

        <!-- <app-footer></app-footer> -->

    </div>

</div>