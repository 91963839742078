import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';


//Angular Material
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatButtonModule} from '@angular/material/button';
import {MatCardModule} from '@angular/material/card';
import {MatTableModule} from '@angular/material/table';
import {MatCheckboxModule} from '@angular/material/checkbox';


// device detector
import { DeviceDetectorModule } from 'ngx-device-detector';


// NgRx
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';

import { appReducers } from './store/app.reducer';
import { effectsArr } from './store/effects';

// Environment
import { environment } from '../environments/environment';

// Rutas
import { AppRoutingModule } from './app-routing.module';

// Ag-grid
import { AgGridModule } from 'ag-grid-angular';

//SmartTable
import { Ng2SmartTableModule } from 'ng2-smart-table';
import { Ng2CompleterModule } from "ng2-completer";


// Modulos personalizados
import { AppComponent } from './app.component';
import { SharedModule } from './shared/shared.module';
import { UsuariosModule } from './usuarios/usuarios.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatRippleModule } from '@angular/material/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule, MatGridListModule, MatBottomSheetModule, MatIconModule } from '@angular/material';
import { DatePipe } from '@angular/common';
import { LoginComponent } from './auth/login/login.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { FooterComponent } from './shared/footer/footer.component';
import { NavbarLoginComponent } from './auth/navbar/navbar.component';
import { PersonalComponent } from './auth/login/usuarios/personal/personal.component';
import { UsuarioComponent } from './auth/login/usuarios/ingreso-personal/ingreso-personal.component';
import { MatSelectModule } from '@angular/material/select';
import { CdkAccordionModule } from '@angular/cdk/accordion';
import { MenuItem } from 'primeng/api';
import {AccordionModule} from 'primeng/accordion';     //accordion and accordion tab
import { CasinoComponent } from './usuarios/casino/casino.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { UbicacionComponent } from './usuarios/ubicacion/ubicacion.component';

import { PdfMakeWrapper } from 'pdfmake-wrapper';
import * as pdfFonts from "pdfmake/build/vfs_fonts";
import { ClientesComponent } from './usuarios/clientes/clientes.component';
import { ServiciosComponent } from './usuarios/servicios/servicios.component'; // fonts provided for pdfmake
import { ButtonRendererComponent } from './usuarios/button-renderer/button-renderer.component';




// If any issue using previous fonts import. you can try this:
// import pdfFonts from "pdfmake/build/vfs_fonts";

// Set the fonts to use
PdfMakeWrapper.setFonts(pdfFonts);



@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    UsuarioComponent,
    PersonalComponent,
    UbicacionComponent,
    CasinoComponent,
    DashboardComponent,
    FooterComponent,
    NavbarLoginComponent,
    ClientesComponent,
    ServiciosComponent,
    ButtonRendererComponent,
  ],
  imports: [
    FontAwesomeModule,
    MatTableModule,
    CdkAccordionModule,
    AccordionModule,
    FormsModule,
    ReactiveFormsModule,
    MatCardModule,
    BrowserModule,
    BrowserAnimationsModule,
    MatGridListModule,
    MatInputModule,
    MatButtonModule,
    MatFormFieldModule,
    MatBottomSheetModule,
    MatInputModule,
    MatRippleModule,
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    MatDatepickerModule,
    MatBottomSheetModule,
    MatButtonModule,
    MatCardModule,
    MatDatepickerModule,
    MatGridListModule,
    MatInputModule,
    MatRippleModule,
    MatIconModule,
    ReactiveFormsModule,
    MatSelectModule,
    MatCheckboxModule,
    Ng2SmartTableModule,
    Ng2CompleterModule,
    StoreModule.forRoot( appReducers ),
    EffectsModule.forRoot( effectsArr ),
    StoreDevtoolsModule.instrument({
      maxAge: 25, // Retains last 25 states
      logOnly: environment.production, // Restrict extension to log-only mode
    }),
    SharedModule,
    UsuariosModule,
    BrowserAnimationsModule,
    DeviceDetectorModule.forRoot(),
    AgGridModule.withComponents([ButtonRendererComponent]),
    
    
  ],
  exports: [
    MatCardModule,
    MatInputModule,
    MatButtonModule,
    MatFormFieldModule,
    MatRippleModule,
    MatDatepickerModule,
    ReactiveFormsModule,
    PersonalComponent,
    UsuarioComponent,
    CasinoComponent,
    UbicacionComponent,
    AccordionModule,

  ],
  providers: [DatePipe],
  bootstrap: [AppComponent]
})
export class AppModule { }
