import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { AppState } from '../../store/app.reducer';

import * as usuarioActions from '../../store/actions';
import { Usuario } from '../../models/usuario.model';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { UsuarioService } from '../../services/usuario.service';
import { IngresoPersonal } from 'src/app/models/Ingreso.model';
import Swal from 'sweetalert2';
import { Codigo } from '../../models/codigo.model';
import { Empresas } from '../../models/empresa.model';
import { DateAdapter, MAT_DATE_FORMATS } from '@angular/material';
import { AppDateAdapter, APP_DATE_FORMATS } from '../date.adapter';
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import { ScriptService } from '../../services/script.service';
import { Personal, Resume, Education, Skill } from '../resme';
pdfMake.vfs = pdfFonts.pdfMake.vfs;

@Component({
  selector: 'app-usuario',
  templateUrl: './ingreso-personal.component.html',
  styles: [],
  providers: [
    {
        provide: DateAdapter, useClass: AppDateAdapter
    },
    {
        provide: MAT_DATE_FORMATS, useValue: APP_DATE_FORMATS
    }
    ]
})
export class UsuarioComponent implements OnInit {

  resume = new Resume();

  degrees = ['B.E.', 'M.E.', 'B.Com', 'M.Com'];

  dd = new Date();
  tipo = 'inactivo';
  usuarios: Usuario[] = [];
  codigo: Codigo = new Codigo();
  codigoQ: Codigo[] = [];
  empresas: Object;
  user: Usuario;
  loading: boolean;
  error: any;
  Empresa: any;
  cod: Object;
  code;
  selected: Empresas["Codigo"];
  login: string;
  selectedEstado: any;

  constructor( private scriptService: ScriptService,
               private router: ActivatedRoute,
               private usuarioService: UsuarioService,
               private route: Router,
               private store: Store<AppState>) { 
                this.resume = JSON.parse(sessionStorage.getItem('codigo')) || new Resume();
                console.log('Loading External Scripts');
                this.scriptService.load('pdfMake', 'vfsFonts');
                }

  forma: FormGroup = new FormGroup({
  'codigo': new FormControl('', Validators.required ),
  'rut': new FormControl('', Validators.required ),
  'nombre': new FormControl('', Validators.required ),
  'empresa': new FormControl('', Validators.required ),
  'descripcion': new FormControl('', )});

  ngOnInit() {
    this.login = localStorage.getItem('login')
    this.getEmpresa()
    this.cod = [{}];
    this.router.params
      .subscribe( params => {
        const id = params['id'];
        this.store.dispatch( new usuarioActions.CargarUsuario(id) );
      });

    this.store.select('usuario')
        .subscribe( usuario => {
          this.user = usuario.user;
          this.loading = usuario.loading;
          this.error = usuario.error;

        });
  }

  generatPdf(){
    const documentDefinition = { content: 'PDF' };
    pdfMake.createPdf(documentDefinition).open({}, window);
    console.log(pdfMake.createPdf(documentDefinition).open())
   }

  getEmpresa(){
    this.usuarioService.getEmpresa().subscribe( resp => {
      this.empresas = resp; 
    });

  }

  getClassEstado(eat: string){
    let cssClasses;
    if(eat == '1'){
      cssClasses = {'fa fa-check':true, 'fas fa-times':false}
    }else{
      cssClasses = {'fa fa-check':false, 'fas fa-times':true}
    }
    return cssClasses;
  }

  getEstado(estado: string){
    let cssClasses;
    if(estado == '1'){
      cssClasses = {'fa fa-check':true, 'fas fa-times':false}
    }else{
      console.log("CERO",estado)
      cssClasses = {'fa fa-check':false, 'fas fa-times':true}
    }
    return cssClasses;
  }



  back(){
    this.route.navigate(['/home']);

  }


  limpiar(){
    this.forma.reset({ monto: 0 });
  }

  buscarCodigoQR(codigoQR: string){

  if(codigoQR.length !== 0 ){
  this.usuarioService.getCodigoQR(codigoQR)
  .subscribe( resp => {
    this.cod = resp;
    console.log("cod",this.cod)

  });
  }
  }

  buscarRut(rut: string){
    this.usuarioService.getRut(rut)
    .subscribe( resp => {
      this.cod = resp;
      if(this.cod  === undefined){
        Swal.fire('rut', rut, 'success');
      } else{
      }
    });
    }






  crearIngreso(codigo: number, rut: any, nombre: any, empresa: number, otro: any, estado: number){

    if(this.tipo === "activo"){
      estado = 0;
    }
    else{
      estado = 1;
    }
    const fechaDay = this.dd.getDate();
    console.log('DIA',fechaDay);

    // const latest_date =this.datepipe.transform(fecha, 'dd-MM-yyyy');
    this.usuarioService.ingresoPersonal(codigo,rut,nombre,empresa,otro,estado)
    .subscribe( resp => {
      const codigoIngresado = nombre;
      console.log("resp",resp)
      this.usuarios = resp;
      // Swal.fire({
      //   title: 'Espere',
      //   text: 'Guardando información',
      //   type: 'info',
      //   allowOutsideClick: false
      // });
      Swal.showLoading();

      Swal.fire('Ingresado Correctamente', codigoIngresado.toString(), 'success');
      this.forma.reset({ monto: 0 });

    });

  }


  // addExperience() {
  //   this.resume.experiences.push(new Personal());
  // }

  // addEducation() {
  //   this.resume.educations.push(new Education());
  // }

  generatePdf(action = 'open') {
    console.log(pdfMake);
    const documentDefinition = this.getDocumentDefinition();

    switch (action) {
      case 'open': pdfMake.createPdf(documentDefinition).open(); break;
      case 'print': pdfMake.createPdf(documentDefinition).print(); break;
      case 'download': pdfMake.createPdf(documentDefinition).download(); break;

      default: pdfMake.createPdf(documentDefinition).open(); break;
    }

  }


  resetForm() {
    this.resume = new Resume();
  }

  getDocumentDefinition() {
    sessionStorage.setItem('codigo', JSON.stringify(this.resume));
    console.log("this.resume",this.resume)
    console.log("getDocumentDefinition",this.resume.CODIGO)

    return {
      content: [
        {
          text: 'RESUME',
          bold: true,
          fontSize: 20,
          alignment: 'center',
          margin: [0, 0, 0, 20]
        },
        {
          columns: [
            [{
              text: this.resume.CODIGO,
              style: 'name'
            },
            {
              text: this.resume.NOMBRE
            },
            {
              text: 'Rut : ' + this.resume.RUT,
            },
            {
              text: 'Empresa : ' + this.resume.EMPRESA,
            },
            {
              text: 'Estado : ' + this.resume.OTRO,
            },
            {
              text: 'GitHub: ' + this.resume.socialProfile,
              link: this.resume.socialProfile,
              color: 'blue',
            }
            ],
            [
              this.getProfilePicObject()
            ]
          ]
        },
        {
          columns : [
              { qr: this.resume.CODIGO + ', Nombre : ' + this.resume.NOMBRE, fit : 100 },
              {
              text: `(${this.resume.CODIGO})`,
              alignment: 'right',
              }
          ]
        }
      ],
      info: {
        title: this.resume.CODIGO + '_RESUME',
        author: this.resume.CODIGO,
        subject: 'RESUME',
        keywords: 'RESUME, ONLINE RESUME',
      },
        styles: {
          header: {
            fontSize: 18,
            bold: true,
            margin: [0, 20, 0, 10],
            decoration: 'underline'
          },
          name: {
            fontSize: 16,
            bold: true
          },
          jobTitle: {
            fontSize: 14,
            bold: true,
            italics: true
          },
          sign: {
            margin: [0, 50, 0, 10],
            alignment: 'right',
            italics: true
          },
          tableHeader: {
            bold: true,
          }
        }
    };
  }

  getExperienceObject(experiences: Personal[]) {

    const exs = [];

    experiences.forEach(experience => {
      exs.push(
        [{
          columns: [
            [{
              text: experience.codigo,
              style: 'codigo'
            },
            {
              text: experience.rut,
            },
            {
              text: experience.nombre,
            }],
            {
              text: 'Experience : ' + experience.empresa + ' Months',
              alignment: 'right'
            }
          ]
        }]
      );
    });

    return {
      table: {
        widths: ['*'],
        body: [
          ...exs
        ]
      }
    };
  }

  getEducationObject(educations: Education[]) {
    return {
      table: {
        widths: ['*', '*', '*', '*'],
        body: [
          [{
            text: 'Degree',
            style: 'tableHeader'
          },
          {
            text: 'College',
            style: 'tableHeader'
          },
          {
            text: 'Passing Year',
            style: 'tableHeader'
          },
          {
            text: 'Result',
            style: 'tableHeader'
          },
          ],
          ...educations.map(ed => {
            return [ed.degree, ed.college, ed.passingYear, ed.percentage];
          })
        ]
      }
    };
  }

  getProfilePicObject() {
    if (this.resume.profilePic) {
      return {
        image: this.resume.profilePic ,
        width: 75,
        alignment : 'right'
      };
    }
    return null;
  }

  fileChanged(e) {
    const file = e.target.files[0];
    this.getBase64(file);
  }

  getBase64(file) {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      console.log(reader.result);
      this.resume.profilePic = reader.result as string;
    };
    reader.onerror = (error) => {
      console.log('Error: ', error);
    };
  }

  // addSkill() {
  //   this.resume.skills.push(new Skill());
  // }


}
