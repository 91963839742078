import { Component, OnInit, ViewChild, ChangeDetectorRef } from '@angular/core';
import { MatTableDataSource, MatSort, MatPaginator, DateAdapter, MAT_DATE_FORMATS } from '@angular/material';
import { Hora } from '../../models/hora.model';
import { Patente } from '../../models/patente.model';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AppState } from '../../store/app.reducer';
import { Store } from '@ngrx/store';
import { DatePipe } from '@angular/common';
import { ExporterService } from '../../services/exporter.service';
import { UsuarioService } from '../../services/usuario.service';
import Swal from 'sweetalert2';
import * as usuariosActions from '../../store/actions';
import { Casino } from '../../models/casino.model';
import { AppDateAdapter, APP_DATE_FORMATS } from '../date.adapter';
import { Empresas } from 'src/app/models/empresa.model';


@Component({
  selector: 'app-casino',
  templateUrl: './casino.component.html',
  styleUrls: ['./casino.component.css'],
  providers: [
    {
        provide: DateAdapter, useClass: AppDateAdapter
    },
    {
        provide: MAT_DATE_FORMATS, useValue: APP_DATE_FORMATS
    }
    ]
})
export class CasinoComponent implements OnInit {

  toppings = new FormControl();

  @ViewChild(MatSort, {static: true}) sort: MatSort;

  
  loading: boolean;
  error: any;
  user: boolean;
  excel: boolean;
  datos: any;
  today: Date;
  date: string;
  use: FormGroup;
  selectedFechaDesde: any;
  selectedFechaHasta: any;
  selectedEmpresa: any;
  selectedRut: any;
  empresas: Empresas[] = [];
  login: string;

  limpiarForm: FormGroup = new FormGroup({
    fechaDesde: new FormControl('', Validators.required),
    fechaHasta: new FormControl('', Validators.required),
    fechaActual: new FormControl(''),
    fechaActualDesde: new FormControl(''),
    empresa: new FormControl('', Validators.required),
    rut: new FormControl('', Validators.required ),

  });
  casinos:  Casino[] = [];
  casinosExport: Casino[] = [];
  casinosFilter: Casino[] = [];
  cenaNoche: any;
  
  totalCenaNoche: number;
  totalDesayuno: number;
  totalAlmuerzo: number;
  totalCena: number;

  //Table
  displayedColumns: string[] = ['rut','nombre','fecha','horaIng','empresa','desay','almuer','cena','cenaNoc','local'];
  dataSource = new MatTableDataSource<CasinoInt>();
  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  fecha: string;
  formGroupName: any;
  fechaCheckActual = false;
  fechaCheckActualDesde = false;


  columnDefs = [
    {headerName: 'Rut', field: 'RUT', width: 90},
    {headerName: 'Nombre', field: 'NOMBRE',width: 270},
    {headerName: 'Fecha', field: 'FECHA',width: 100},
    {headerName: 'Hora', field: 'HORA_INGRESO',width: 60},
    {headerName: 'Empresa', field: 'EMPRESA',width: 130},
    {headerName: 'Des.', field: 'DESAYUNO',width: 50},
    {headerName: 'Alm.', field: 'ALMUERZO',width: 50},
    {headerName: 'Cen.', field: 'CENA', width: 50},
    {headerName: 'C.N.', field: 'CENA_NOCHE',width: 50},
    {headerName: 'Casino', field: 'UBICACION',width: 100}
  ];

  rowData: Casino[]=[];

  constructor( 
    public datepipe: DatePipe,
    private store: Store<AppState>, 
    private router: Router,
    private crd: ChangeDetectorRef,
    private excelService:ExporterService,
    private usuarioService: UsuarioService ) { }

  ngOnInit() {

    this.cenaNoche
    this.login = localStorage.getItem('login');
    this.today =new Date();
    this.store.dispatch( new usuariosActions.CargarUsuarios() );
    this.getOnlyEmpresa();
  }

  getOnlyEmpresa(){
    this.usuarioService.getOnlyEmpresa()
    .subscribe( response =>{
      this.empresas = response;
      console.log("Get Empresa",this.empresas)
      this.crd.detectChanges();
    });
  }


  

  getTotalCost() {
    this.totalCenaNoche = this.dataSource.data.map(t => +t.CENA_NOCHE).reduce((acc, value) => acc + value, 0);
    this.totalDesayuno = this.dataSource.data.map(t => +t.DESAYUNO).reduce((acc, value) => acc + value, 0);
    this.totalAlmuerzo = this.dataSource.data.map(t => +t.ALMUERZO).reduce((acc, value) => acc + value, 0);
    this.totalCena = this.dataSource.data.map(t => +t.CENA).reduce((acc, value) => acc + value, 0);
  }

  getClassTick(eat: string){
    let cssClasses;
    if(eat == '1'){
      cssClasses = {'fa fa-check text-success':true, 'fas fa-times text-danger':false}
    }else{
      cssClasses = {'fa fa-check text-success':false, 'fas fa-times text-danger':true}
    }
    return cssClasses;
  }
  filterEmpresas(){
    this.casinosFilter=[];
    if(this.toppings.value != 0){
    this.toppings.value.forEach(element => {

      this.casinos.forEach(element1 => 
        {
            if( element1.EMPRESA == element.Empresa ){
              this.casinosFilter.push(element1);
              console.log("Este si ", element1 );
        }
        
      });
      this.dataSource.data = this.casinosFilter;
      this.casinosExport = this.casinosFilter;
      this.getTotalCost();
      
    });
  }else{
    this.dataSource.data = this.casinos;
    this.casinosExport = this.casinos;
    this.getTotalCost();
  }

  
  }

  datosCasino( fechaDesde: string,fechaHasta: string, selectedRut: string, selectedEmpresa : string) {
    
    this.loading = true;
    this.excel = false;
    this.user = false;
    const dateDesde =this.datepipe.transform(fechaDesde, 'dd-MM-yyyy');
    // const dateHasta =fechaHasta;
    console.log("dateDesde",dateDesde)

    const dateHasta = this.datepipe.transform(fechaHasta ,'dd-MM-yyyy');
    console.log("dateHasta",dateHasta)
    console.log("Empresa",selectedEmpresa)

    if(dateDesde !== undefined && dateHasta !== undefined && dateDesde !== null && dateHasta !== null  ){
      // if(fechaDesde!=null){
        // if (fechaDesde === undefined && fechaHasta === undefined){
        // hora = '';
        // patente = '';
        console.log("rut",selectedRut);
        console.log("Empresa",selectedEmpresa);

        this.usuarioService.getCasino(dateDesde,dateHasta,selectedRut,selectedEmpresa)
        .subscribe( resp => {


        this.casinos = resp;
        console.log("this.casinos ", this.casinos);

        this.rowData = [...this.casinos];

        this.casinosExport = resp;
        this.dataSource.data = this.casinos;
        this.dataSource.paginator = this.paginator;
        this.getTotalCost();


        for(const cas of this.casinos){
          const cenaN = cas.CENA_NOCHE;
          this.cenaNoche = cenaN;
        }
        // this.casinos.forEach(element => console.log('element', element))
        if(this.casinos.length != 0){
        this.loading = false;
        this.user = false;
        this.excel = true;
        }
        else {
          this.user = true;
          this.loading = false;
          this.excel = false;
          }
        });
        // }
    this.crd.detectChanges();
    // }
  }
  else
    this.loading = false;
  this.toppings.reset();
  }

  limpiar(){
    this.crd.markForCheck();
    this.loading = false;
    this.excel = false;
    this.limpiarForm.reset();
    this.user = false;
    this.limpiarForm.controls['fechaHasta'].enable();
    this.limpiarForm.controls['fechaDesde'].enable();

  }





  exportAsXLSX():void {

    if(this.casinosExport.length != 0){
      console.log("this.usuarios.length",this.casinosExport.length)
    this.excelService.exportAsExcelFile(this.casinosExport, 'home');
  } else{
    Swal.fire({
      icon: 'error',
      title: 'Oops...',
      text: 'No se encuentran datos a exportar!',
    })
  }

  }

  fechaActual(){
    this.fechaCheckActual = this.limpiarForm.controls['fechaActual'].value;
    console.log('fechaCheckActual', this.fechaCheckActual);

    if(this.selectedFechaHasta === undefined || this.selectedFechaHasta === null){
      this.fechaCheckActual = false;
    }
    if(this.fechaCheckActual === false){
      this.limpiarForm.controls['fechaHasta'].disable();
      this.selectedFechaHasta = this.today;
      console.log('if today', this.selectedFechaHasta);
      // this.datosCasino( this.selectedFechaDesde,this.selectedFechaHasta);
    } else{
      this.limpiarForm.controls['fechaHasta'].enable();
      console.log('else today', this.selectedFechaHasta);
    }
    this.crd.detectChanges();
  }

  fechaActualDesde(){
    this.fechaCheckActualDesde = this.limpiarForm.controls['fechaActualDesde'].value;
    console.log('fechaCheckActualDesde', this.fechaCheckActualDesde);

    if(this.selectedFechaDesde === undefined || this.selectedFechaDesde === null){
      this.fechaCheckActualDesde = false;
    }
    if(this.fechaCheckActualDesde === false){
      this.limpiarForm.controls['fechaDesde'].disable();
      this.selectedFechaDesde = this.today;
      console.log('if today', this.selectedFechaDesde);
      // this.datosCasino( this.selectedFechaDesde,this.selectedFechaHasta);
    } else{
      this.limpiarForm.controls['fechaDesde'].enable();
      console.log('else today', this.selectedFechaDesde);
    }
    this.crd.detectChanges();
  }

}

export interface CasinoInt {
  CODIGO: number,
  RUT: string,
  NOMBRE: string,
  FECHA: string,
  HORA_INGRESO: string,
  EMPRESA: string,
  CENA_NOCHE: number,
  DESAYUNO: number,
  ALMUERZO: number,
  CENA: number,
  NUMERO: string,
  UBICACION: string,
}
