import { Component, OnInit, ɵConsole } from '@angular/core';
import { UsuarioService } from 'src/app/services/usuario.service';
import { Router } from '@angular/router';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Conductor } from 'src/app/models/conductor.model';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-conductor',
  templateUrl: './conductor.component.html',
  styleUrls: ['./conductor.component.css']
})
export class ConductorComponent implements OnInit {

  respuesta: any;
  RutSearch: string ;
  conductor: Conductor;
  cod: Object;
  forma: FormGroup = new FormGroup({
    // 'rut': new FormControl('', Validators.required ),
    'Conductor': new FormControl('', Validators.required ),
    'Usuario': new FormControl('', Validators.required ),
    'Password': new FormControl('', Validators.required ),
    'Rut': new FormControl('', Validators.required )});
    

  constructor(
               private usuarioService: UsuarioService,
               private route: Router,
  ) { }

  ngOnInit() {
    this.cod = [{}];
  }

  getUserByRut(rut: string){
    this.usuarioService.getCondutorByRut(rut).subscribe(
      response =>{
        if(response){
          this.conductor = response;
          this.RutSearch = rut;
          console.log("rut:",this.RutSearch);
          if(this.conductor[0].Conductor !== "RUT INCORRECTO"){
            this.cod = response;
          }else{
            this.RutSearch = null;
            Swal.fire("CONDUCTOR NO REGISTRADO", 'Rut no encontrado', 'error');
          }
        }else{
          console.error('Error eh!');
        }
      });

  }
  updateConductor(rut: string,nombre: string, usuario: string, pass: string){
    console.log('rut ingresado:',rut);
    this.usuarioService.updateConductor(rut,nombre,usuario,pass).subscribe(response =>{
      this.respuesta =response;
      console.log(response);
      if(this.respuesta[0].Respuesta==="NOMBRE DE USUARIO YA EXISTE")
        Swal.fire(this.respuesta[0].Respuesta ,usuario, 'error');
      else{
      Swal.fire(this.respuesta[0].Respuesta , rut , 'success');
      this.limpiar();}
    });
  }
  
  limpiar(){
    this.RutSearch = null;
    this.forma.reset({ monto: 0 });
  }

  validarRut(rut :string){
  //Soon....
  }


}
