<nav class="navbar navbar-expand-sm navbar-light" style="background-color: #e3f2fd;">
    <div class="text-center navbar-brand-wrapper d-flex align-items-top justify-content-center">
        <a class="navbar-brand brand-logo">
            <img src="assets/imagenes/logo_2023_4.png" alt="logo" />
        </a>
    </div>

    <!-- <div class="collapse navbar-collapse" id="navbarSupportedContent"> -->
    <ul class="navbar-nav mr-auto">
        <li class="nav-item" routerLinkActive="active">
            <a class="nav-link" routerLink="/conductor">Cotizacion</a>
        </li>
        <li class="nav-item" routerLinkActive="active">
            <a class="nav-link" routerLink="/servicio">Servicios</a>
        </li>
        <li class="nav-item" routerLinkActive="active">
            <a class="nav-link" routerLink="/cliente">Servicios</a>
        </li>
        <li class="nav-item" routerLinkActive="active">
            <a class="nav-link" routerLink="/cliente">Estados</a>
        </li>
    </ul>
    <ul class="navbar-nav mr-auto">
        <li class="nav-item" routerLinkActive="active">
            <a class="nav-link" routerLink="/login">Cerrar Sesion</a>
        </li>
    </ul>

    <!-- <div>
        <a class="navbar-brand" routerLink="/home">Viajes</a>
        <ul class="navbar-nav mr-auto">
            <li class="nav-item" routerLinkActive="active">
                <a class="nav-link" routerLink="/home">Home</a>
            </li>
            <li class="nav-item" routerLinkActive="active">
                <a class="nav-link" routerLink="/personal">Ingreso de Personal</a>
            </li>
        </ul>
        <ul class="navbar-nav mr-auto">
            <li class="nav-item" routerLinkActive="active">
                <a class="nav-link" (click)="loggout()">Cerrar Sesion</a>
            </li>
        </ul>
    </div> -->


    <!-- </div> -->
</nav>