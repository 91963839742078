import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ColDef } from 'ag-grid-community';
import { UsuarioService } from 'src/app/services/usuario.service';
import Swal from 'sweetalert2';
import { CotizacionDet } from 'src/app/models/cotizaciondet.model';
import { Cotizacion } from 'src/app/models/cotizacion.model';
import { CotizacionList } from 'src/app/models/cotizacionList.models';

import { ButtonRendererComponent } from '../button-renderer/button-renderer.component';

interface DatosServicio{
  id:string,
  codigo:string,
  nombre:string
}

@Component({
  selector: 'app-servicios',
  templateUrl: './servicios.component.html',
  styleUrls: ['./servicios.component.css']
})

export class ServiciosComponent implements OnInit {

  rutControl = new FormControl('', Validators.required);
  cotizacionControl = new FormControl('', Validators.required);
  datosCliente: FormGroup = new FormGroup({
    'FECHA': new FormControl('', Validators.required),
    'NOMBRE': new FormControl('', Validators.required),
    'GIRO': new FormControl('', Validators.required),
    'DIRECCION': new FormControl('', Validators.required),
    'CIUDAD': new FormControl('', Validators.required),
    'CONTACTO': new FormControl('', Validators.required),
    'REFERENCIA': new FormControl('', Validators.required),
    'TOTAL': new FormControl('', Validators.required),
  });

  datosCotizaciones: FormGroup = new FormGroup({
    'NUMERO': new FormControl('', Validators.required),
    'FECHA': new FormControl('', Validators.required),
    'REFERENCIA': new FormControl('', Validators.required),
    'TOTAL': new FormControl('', Validators.required),
  });




  rutSearch: string;
  cotizacionSearch: string;
  cotizacion: Cotizacion[];
  cotizacionDet: CotizacionDet[];
  cotizacionList: CotizacionList[];
  rowDataClicked1 = {};
  rowDataClicked2 = {};
  rowData = [];
  rowDataCot = [];

  mostrarOT: boolean = false;
  mostrarGrilla: boolean = false;
  mostrarGrillaCot: boolean = false;

  numero: string;
  cod: Object;

  datosServicio:DatosServicio;
  nombreServivicio:string;


  frameworkComponents: any;
  frameworkComponentsCot: any;

  constructor(
    private usuarioService: UsuarioService,) {
      this.frameworkComponents={
      buttonRenderer: ButtonRendererComponent
    }
    this.frameworkComponentsCot={
      buttonRenderer: ButtonRendererComponent
    }
   }


   columnDefs: ColDef[] = [
    { field: 'ID', cellClass: "cell-border cell-vertical-align-text-right", resizable: true, width: 45 },
    { field: 'CODIGO', resizable: true, width: 80,autoHeight:true,cellStyle: {'white-space': 'normal'} ,cellClass: "cell-border cell-vertical-align-text-left"},
    { field: 'NOMBRE', resizable: true, width: 400 ,autoHeight:true,cellStyle: {'white-space': 'normal'} ,cellClass: "cell-border cell-vertical-align-text-left"},
    { headerName: 'UNID', field: 'UNIDAD', resizable: true, width: 70 , autoHeight:true,cellStyle: {'white-space': 'normal'} ,cellClass: "cell-border cell-vertical-align-text-left"},
    { headerName: 'CANT',field: 'CANTIDAD', cellClass: "cell-border cell-vertical-align-text-right", resizable: true, width: 70 },
    { headerName: '$ UNIT',field: 'VALUNITARIO', cellClass: "cell-border cell-vertical-align-text-right", resizable: true, width: 130 },
    { headerName: 'TOTAL',field: 'VALTOTAL', cellClass: "cell-border cell-vertical-align-text-right", resizable: true, width: 130 },
    { headerName: 'OT',field: 'CANT_OT', cellClass: "cell-border cell-vertical-align-text-right", resizable: true, width: 80 },
     {headerName: '', width: 40 , cellRenderer: 'buttonRenderer', cellClass: "cell-border cell-vertical-align-text-center", resizable: true , 
      cellRendererParams: {
      onClick: this.onBtnClick1.bind(this),
      label: '', 
    }},
  ];


  columnDefsCot: ColDef[] = [
    { field: 'NUMERO', resizable: true, width: 80,autoHeight:true,cellStyle: {'white-space': 'normal'} ,cellClass: "cell-border cell-vertical-align-text-left"},
    { field: 'FECHA', resizable: true, width: 80,autoHeight:true,cellStyle: {'white-space': 'normal'} ,cellClass: "cell-border cell-vertical-align-text-left"},
    { field: 'REFERENCIA', resizable: true, width: 400 ,autoHeight:true,cellStyle: {'white-space': 'normal'} ,cellClass: "cell-border cell-vertical-align-text-left"},
    { headerName: 'IVA',field: 'IVA', cellClass: "cell-border cell-vertical-align-text-right", resizable: true, width: 130 },
    { headerName: 'NETO',field: 'NETO', cellClass: "cell-border cell-vertical-align-text-right", resizable: true, width: 130 },
    { headerName: 'TOTAL',field: 'TOTAL', cellClass: "cell-border cell-vertical-align-text-right", resizable: true, width: 130 },

  ];






  ngOnInit(): void {
    this.cod = [{}];
  }


  getUserByRut(rut: string) {
    console.log(rut);
  }

  ListaCotizaciones(rut: string) {
    const [rut2, nombre, giro, ciudad, contacto, telefono, correo, direccion] = Object.values(this.cod[0]);
    this.usuarioService.getCotizacionList(rut).subscribe(
      response => {
        this.cotizacionList = response;
        this.rowDataCot = this.cotizacionList;
        console.log(this.cotizacionList)
        const [valor] = Object.values(response[0]);
        this.mostrarGrillaCot = true;
        this.numero = valor;

        console.log('nummero rescatado>Ju:' + this.numero)
      }
    );
  }


  getCotizacion(rut: string, numero: string) {
    if (rut !== '') {
      this.usuarioService.getCotizacion(rut,numero).subscribe(
        response => {
          if (response) {
            this.cotizacion = response;
            console.log(this.cotizacion)
            const [dato1] = Object.values(response[0]);
            this.rutSearch = rut;
            this.mostrarOT = false;
            this.mostrarGrilla = true;
            if (dato1 !== "") {
            } else {
              Swal.fire('Error', 'Rut:' + rut + ' No Existe');
            }
          } else {
            console.error('Error eh!');
          }
        });
    }
  }


  getCotizacionDet(rut: string, numero: string) {
    if (rut !== '') {
      this.usuarioService.getCotizacionDet(rut,numero).subscribe(
        response => {
          if (response) {
            this.cotizacionDet = response;
            this.rowData = this.cotizacionDet;
            const [dato1] = Object.values(response[0]);
            this.rutSearch = rut;
            this.getCotizacion(rut,numero);
            if (dato1 !== "") {
            } else {
              Swal.fire('Error', 'Rut:' + rut + ' No Existe');
            }
          } else {
            console.error('Error eh!');
          }
        });
    }
  }

  onBtnClick1(e) {
    this.mostrarOT = true;
    this.mostrarGrilla = false;
    this.rowDataClicked1 = e.rowData;
    const[valor1,valor2,valor3,valor4] = Object.values(this.rowDataClicked1);
    console.log(valor1);
    this.nombreServivicio = valor3;
    
  }
  
  onBtnClick2(e) {
    this.rowDataClicked2 = e.rowData;
  }

  addOT() {
    console.log("Guaradar OT");
  }
  
  cancel() {
    console.log("Cancelar");
  }



}
