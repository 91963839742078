import { Component, OnInit, ViewChild, ChangeDetectorRef } from '@angular/core';
import { Usuario } from '../../../../models/usuario.model';
import { Store } from '@ngrx/store';
import { AppState } from '../../../../store/app.reducer';
import * as usuariosActions from '../../../../store/actions';
import { Router } from '@angular/router';
import { UsuarioService } from '../../../../services/usuario.service';
import { DatePipe } from '@angular/common';
import { Hora } from '../../../../models/hora.model';
import { Patente } from '../../../../models/patente.model';
import { FormGroup, Validators, FormControl } from '@angular/forms';
import { MatSort, MatTableDataSource } from '@angular/material';
import { ExporterService } from '../../../../services/exporter.service';

export interface PeriodicElement {
  name: string;
  position: number;
  weight: number;
  symbol: string;
} 

const ELEMENT_DATA: PeriodicElement[] = [
  {position: 1, name: 'Hydrogen', weight: 1.0079, symbol: 'H'},
  {position: 2, name: 'Helium', weight: 4.0026, symbol: 'He'},
  {position: 3, name: 'Lithium', weight: 6.941, symbol: 'Li'},
  {position: 4, name: 'Beryllium', weight: 9.0122, symbol: 'Be'},
  {position: 5, name: 'Boron', weight: 10.811, symbol: 'B'},
  {position: 6, name: 'Carbon', weight: 12.0107, symbol: 'C'},
  {position: 7, name: 'Nitrogen', weight: 14.0067, symbol: 'N'},
  {position: 8, name: 'Oxygen', weight: 15.9994, symbol: 'O'},
  {position: 9, name: 'Fluorine', weight: 18.9984, symbol: 'F'},
  {position: 10, name: 'Neon', weight: 20.1797, symbol: 'Ne'},
];



@Component({
  selector: 'app-personal',
  templateUrl:'./personal.component.html',
  styles: []
})
export class PersonalComponent implements OnInit {

  displayedColumns: string[] = ['position', 'name', 'weight', 'symbol'];
  dataSource = new MatTableDataSource(ELEMENT_DATA);

  @ViewChild(MatSort, {static: true}) sort: MatSort;

  
  usuarios: Usuario[] = [];
  loading: boolean;
  error: any;
  user: boolean;
  datos: any;
  today: Date;
  date: string;
  horas: Hora[];
  patente: Patente[];
  use: FormGroup;

  limpiarForm: FormGroup = new FormGroup({
    fecha: new FormControl('', Validators.required),
    hora: new FormControl('', Validators.required),
    patente: new FormControl('', Validators.required)
  });
  selectedHora: any;
  selectedPatente: any;
  selectedFecha: any;
  login: string;

  constructor( 
    public datepipe: DatePipe,
    private store: Store<AppState>, 
    private router: Router,
    private crd: ChangeDetectorRef,
    private excelService:ExporterService,
    private usuarioService: UsuarioService ) { }

  ngOnInit() {

    this.login = localStorage.getItem('login')
    console.log("LOGIN",this.login)
    this.dataSource.sort = this.sort;

    this.today =new Date();

    this.store.select('usuarios')
        .subscribe( usuarios => {
          this.usuarioService.getHora()
          this.usuarios = usuarios.users;
          this.loading = usuarios.loading;
          this.error = usuarios.error;

        });


    this.getHora();
    this.getPatente();
    this.store.dispatch( new usuariosActions.CargarUsuarios() );
  }

  getHora(){
    this.usuarioService.getHora().subscribe( resp => {
      this.horas = resp; 
    });
    this.crd.detectChanges();
  }

  irUsuario( fecha: string,fechaFin: string, hora: string,patente: string, rut: string, empresa: string ) {
    this.loading = true;
    const date =this.datepipe.transform(fecha, 'dd-MM-yyyy');
    const dateFin =this.datepipe.transform(fechaFin, 'dd-MM-yyyy');


    if(fecha !== undefined){
      if(fecha!=null){
        if (hora === undefined && patente === undefined){
        console.log("fecha",fecha)
        hora = '';
        patente = '';
        this.usuarioService.getDatos(date,dateFin,hora,patente,rut,empresa)
        .subscribe( resp => {
        this.usuarios = resp;
        if(this.usuarios.length != 0){
        this.loading = false;
        this.user = false;
        }
        else{
          this.user = true;
          this.loading = false;
  
          }
        });
        }
        if(patente === null && hora === null){
          hora = '';
          patente = '';
          this.usuarioService.getDatos(date,dateFin,hora,patente,rut,empresa)
          .subscribe( resp => {
            this.usuarios = resp;
            if(this.usuarios.length != 0){
            this.loading = false;
            this.user = false;
            }
            else{
            this.user = true;
            this.loading = false;
    
            }
          });
          this.crd.detectChanges();
        }
        if(hora !== undefined && patente === undefined){
          patente = '';
          this.usuarioService.getDatos(date,dateFin,hora,patente,rut,empresa)
          .subscribe( resp => {
          this.usuarios = resp;
          if(this.usuarios.length != 0){
          this.loading = false;
          this.user = false;
          }
          else{
            this.user = true;
            this.loading = false;
    
            }
          });
        }
        if(hora !== null && patente === null){
          patente = '';
          this.usuarioService.getDatos(date,dateFin,hora,patente,rut,empresa)
          .subscribe( resp => {
          this.usuarios = resp;
          if(this.usuarios.length != 0){
          this.loading = false;
          this.user = false;
          }
          else{
            this.user = true;
            this.loading = false;
    
            }
          });
        }
        if(hora === undefined && patente !== undefined){
          hora = '';
          this.usuarioService.getDatos(date,dateFin,hora,patente,rut,empresa)
          .subscribe( resp => {
          this.usuarios = resp;
          if(this.usuarios.length != 0){
          this.loading = false;
          this.user = false;
          }
          else{
            this.user = true;
            this.loading = false;
    
            }
          });
        }
        if(hora === null && patente !== null){
          hora = '';
          this.usuarioService.getDatos(date,dateFin,hora,patente,rut,empresa)
          .subscribe( resp => {
          this.usuarios = resp;
          if(this.usuarios.length != 0){
          this.loading = false;
          this.user = false;
          }
          else{
            this.user = true;
            this.loading = false;
    
            }
          });
        }
        if(hora !== "" && patente !== ""){
          console.log("1")
          this.usuarioService.getDatos(date,dateFin,hora,patente,rut,empresa)
          .subscribe( resp => {
          this.usuarios = resp;
          if(this.usuarios.length != 0){
          this.loading = false;
          this.user = false;
          }
          else{
            this.user = true;
            this.loading = false;
    
            }
          });
        }



    this.crd.detectChanges();
    }
  }  
  }
  // limpiar(fecha: string,hora: string,patente: string): void {
  //   // this.selectedHora = undefined;
  //   // this.selectedPatente = undefined;
  //   // this.limpiarForm.reset();
  //   // this.user = false;

  //   const resetFecha = {};
  //   resetFecha[fecha] = ''
  //   const resetHora = {};
  //   resetHora[hora] = ''
  //   const resetPatente = {};
  //   resetPatente[patente] = ''

  //   this.limpiarForm.patchValue(resetFecha, resetHora);
  //   console.log("this.limpiarForm.patchValue(resetFecha, resetHora),",this.limpiarForm.patchValue(resetFecha, resetHora))
  // }
  limpiar(){
    this.crd.markForCheck();
    this.loading = true;
    this.limpiarForm.reset();
    this.user = false;
  }


  getPatente(){
    this.usuarioService.getPatente().subscribe( resp => {
      this.patente = resp;
    });
    this.crd.detectChanges();
  }

  exportAsXLSX():void {
    this.excelService.exportAsExcelFile(this.usuarios, 'home');
  }

}
