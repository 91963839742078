<div class="row">
    <div class="col-12">
        <div class="card">
            <div class="card-body">
                <h5 class="card-title d-flex align-items-center">
                    <div>
                        <i class="material-icons">
                            description
                        </i> Cotización
                    </div>
                </h5>
                <div class="form-row">
                    <div class="form-group col-1">
                        <label class="col-form-label"> Rut</label>
                    </div>
                    <div class="form-group col-2">
                        <input type="text" #txtRut2 class="form-control" placeholder="12345678-1"
                            (focusout)="getUserByRut(txtRut2.value)" (keyup.enter)="getUserByRut(txtRut2.value)"
                            [formControl]="rutIngresado" [(ngModel)]="rutSearch" name="rutSearch">
                    </div>
                    <div class="form-group col-1">
                        <button (click)="getUserByRut(txtRut2.value)"
                            class="btn btn-outline-warning my-2 my-sm-0 ">Aceptar</button>
                    </div>
                    <div class="form-group col-6">
                        <button (click)="saveClientes(txtRut2.value)"
                            class="btn btn-outline-warning my-2 my-sm-0 ">Guardar Datos Cliente</button>
                    </div>

                </div>
                <form [formGroup]="datosCliente" *ngFor="let item of cod">
                    <div class="form-row">
                        <div class="form-group col-1">
                            <label class="col-form-label"> Empresa</label>
                        </div>
                        <div class="form-group col-11">
                            <input type="text" #txtNombre class="form-control" placeholder="Empresa"
                                formControlName="NOMBRE" [(ngModel)]="item.NOMBRE" name="NOMBRE">
                        </div>
                    </div>
                    <div class="form-row">
                        <div class="form-group col-1">
                            <label class="col-form-label"> Dirección</label>
                        </div>
                        <div class="form-group col-6">
                            <input type="text" #txtDireccion class="form-control" placeholder="Dirección"
                                formControlName="DIRECCION" [(ngModel)]="item.DIRECCION" name="DIRECCION">
                        </div>
                        <div class="form-group col-1">
                            <label class="col-form-label"> Ciudad</label>
                        </div>
                        <div class="form-group col-4">
                            <input type="text" #txtCiudad class="form-control" placeholder="Ciudad"
                                formControlName="CIUDAD" [(ngModel)]="item.CIUDAD" name="CIUDAD">
                        </div>
                    </div>
                    <div class="form-row">
                        <div class="form-group col-1">
                            <label class="col-form-label"> Giro</label>
                        </div>
                        <div class="form-group col-6">
                            <input type="text" #txtGiro class="form-control" placeholder="Giro" formControlName="GIRO"
                                [(ngModel)]="item.GIRO" name="GIRO">
                        </div>
                        <div class="form-group col-1">
                            <label class="col-form-label"> Contacto</label>
                        </div>
                        <div class="form-group col-4">
                            <input type="text" #txtContacto class="form-control" placeholder="Contacto"
                                formControlName="CONTACTO" [(ngModel)]="item.CONTACTO" name="CONTACTO">
                        </div>
                    </div>

                </form>
            </div>
        </div>


        <div class="card">
            <div class="card-body">
                <div class="form-row">
                    <div class="form-group col-1">
                        <label>Referencia</label>
                    </div>
                    <div class="form-group col-11">
                        <input type="text" #txtRerencia class="form-control" placeholder="Referencia"
                            [formControl]="referenciaControl" [(ngModel)]="referencia" name="referencia">
                    </div>
                </div>
                <div class="form-row">
                    <div class="form-group col-6">
                        <mat-form-field class="form-control">
                            <mat-label>Tipo de Servicio</mat-label>
                            <mat-select (selectionChange)="getProductos()" [formControl]="productoControl"
                                [(ngModel)]="selectedValue" name="servicio" required>
                                <mat-option *ngFor="let servicio of servicios" [value]="servicio.value">
                                    {{servicio.viewValue}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <div class="form-group col-6">
                        <mat-form-field class="form-control">
                            <mat-label>Nombre del Servicio</mat-label>
                            <mat-select (selectionChange)="setProductos()" [formControl]="productoControl"
                                [(ngModel)]="codigoSeleccionado" name="producto" required>
                                <mat-option *ngFor="let producto of productos" [value]="producto.value">
                                    {{producto.viewValue}}
                                </mat-option>
                            </mat-select>
                            <mat-error *ngIf="productoControl.hasError('required')">Elija un servicio</mat-error>
                            <mat-hint>{{productoControl.value?.value}}</mat-hint>
                        </mat-form-field>
                    </div>
                </div>
                <form [formGroup]="datosProducto" *ngFor="let itemCotizacion of detalle">
                    <div class="form-row">
                        <div class="form-group col-2">
                            <input type="text" #txtcodigo class="form-control" placeholder="Código"
                                formControlName="CODIGO" [(ngModel)]="itemCotizacion.CODIGO" name="CODIGO">
                        </div>
                        <div class="form-group col-4">
                            <input type="text" #txtnombre class="form-control" placeholder="Nombre"
                                formControlName="NOMBRE" [(ngModel)]="itemCotizacion.NOMBRE" name="NOMBRE">
                        </div>
                        <div class="form-group col-1">
                            <input type="text" #txtunidad class="form-control" placeholder="unidad"
                                formControlName="UNIDAD" [(ngModel)]="itemCotizacion.UNIDAD" name="UNIDAD">
                        </div>
                        <div class="form-group col-1">
                            <input type="text" #txtcantidad class="form-control" placeholder="Cant."
                                (change)="calculaTotal(itemCotizacion.CANTIDAD,itemCotizacion.PRECIO)"
                                formControlName="CANTIDAD" [(ngModel)]="itemCotizacion.CANTIDAD" name="CANTIDAD">
                        </div>
                        <div class="form-group col-2">
                            <input type="text" #txtvalorUnitario class="form-control" placeholder="Valor Unitario"
                                (change)="calculaTotal(itemCotizacion.CANTIDAD,itemCotizacion.PRECIO)"
                                formControlName="PRECIO" [(ngModel)]="itemCotizacion.PRECIO" name="PRECIO">
                        </div>
                        <!-- <div class="form-group col-2">
                            <input type="text" #txtvalorNeto class="form-control" placeholder="Total"
                                [formControl]="totalItemControl" [(ngModel)]="valorNeto" name="valorNeto">
                        </div> -->
                        <div class="form-group col-2">
                            <button
                                (click)="addProducto(itemCotizacion.CODIGO,itemCotizacion.NOMBRE,itemCotizacion.UNIDAD,itemCotizacion.CANTIDAD,itemCotizacion.PRECIO,valorNeto)"
                                class="btn btn-outline-warning my-2 my-sm-0 "> Agregar Servicio</button>
                        </div>
                    </div>
                </form>
                <div class="form-row">
                    <ag-grid-angular style="width: 100%; height: 200px;" class="ag-theme-balham" [rowData]="rowData"
                        [columnDefs]="columnDefs">
                    </ag-grid-angular>
                </div>
            </div>
        </div>


        <div class="card">
            <div class="card-body">
                <h5 class="card-title d-flex align-items-center">
                    <div>
                        <i class="material-icons">
                            description
                        </i> Términos y condiciones
                     <div>Considera:</div>
                    </div>
                </h5>
                <div class="form-group col-md-2">
                </div>
                <div class="form-row">


                    <div class="form-group col-md-4">
                    <mat-checkbox (change)="FpermisosViales($event)" class="example-margin" >Permisos Viales </mat-checkbox>
                    <mat-checkbox (change)="FestudiosRuta($event)" class="example-margin">Estudios de Ruta, Puentes</mat-checkbox>
                    <mat-checkbox (change)="FescoltaPolicia($event)"class="example-margin">Escolta Policial y/o Privada</mat-checkbox>
                    </div>
                    <div class="form-group col-md-2">
                    </div>
                    <div class="form-group col-md-4">
                    <mat-checkbox (change)="FcargaHasta($event)" class="example-margin">Seguro de Carga hasta UF$5000.</mat-checkbox>
                    <mat-checkbox (change)="FcargaSobre($event)" class="example-margin">Seguro de Carga sobre UF$5000.</mat-checkbox>
                    <mat-checkbox (change)="FsobreEstadia($event)" class="example-margin">Sobre estadía cada 12 hrs de estadia en origen y/o destino</mat-checkbox>
                    </div>
                </div>
                <h5>
                    <div> Valores sobre estadía por día:</div>
                </h5>
                <div class="form-group col-md-2">
                </div>
                <div class="form-row">
                    <div class="form-group col-md-2">
                    <mat-checkbox (change)="FcamaBaja($event)" class="example-margin">Cama Baja</mat-checkbox>
                    <mat-checkbox (change)="FportaMaq($event)" class="example-margin">Porta Maq.</mat-checkbox>
                    <mat-checkbox (change)="Frampla($event)" class="example-margin">Rampla</mat-checkbox>
                    <mat-checkbox (change)="FlModular($event)" class="example-margin">L.Modular</mat-checkbox>
                    </div>
                    <div class="form-group col-md-2">
                        <input type="text" #camaBajaVal class="form-control" [formControl]="camaBajaControl" [(ngModel)]="camaBajaValor" name="camaBajaValor">
                        <input type="text" #portaMaq class="form-control" [formControl]="portaMaqControl" [(ngModel)]="portaMaqValor" name="portaMaqValor">
                        <input type="text" #rampla class="form-control" [formControl]="ramplaControl" [(ngModel)]="ramplaValor" name="ramplaValor">
                        <input type="text" #lModular class="form-control" [formControl]="lModularControl" [(ngModel)]="lModularValor" name="lModularValor">
                    </div>
                    <div class="form-group col-md-2">
                    </div>

                    <div class="form-group col-md-2">
                        <mat-checkbox (change)="Fdolly($event)" class="example-margin">Dolly</mat-checkbox>
                        <mat-checkbox (change)="FextencionModular($event)" class="example-margin">Extensión modular</mat-checkbox>
                        <mat-checkbox (change)="FvigaModular($event)" class="example-margin">Viga modular</mat-checkbox>
                        <mat-checkbox (change)="FtractoTiro($event)"class="example-margin">Tracto de Tiro</mat-checkbox>
                        </div>
                        <div class="form-group col-md-2">
                            <input type="text" #dolly class="form-control" [formControl]="dollyControl" [(ngModel)]="dollyValor" name="dollyValor">
                            <input type="text" #extensionModular class="form-control" [formControl]="extencionModularControl" [(ngModel)]="extencionModularValor" name="extencionModularValor">
                            <input type="text" #vigaModular class="form-control" [formControl]="vigaModularControl" [(ngModel)]="vigaModularValor" name="vigaModularValor">
                            <input type="text" #tractoTiro class="form-control" [formControl]="tractoTiroControl" [(ngModel)]="tractoTiroValor" name="tractoTiroValor">
                        </div>
                </div>
                
            </div>
        </div>



        <div class="card">
            <div class="card-body">
                <div class="form-row">
                    <div class="form-group col-md-3">
                        <button (click)="addCotizacion()" type="submit" class="btn btn-success mr-2">
                            <i class="fa fa-save"></i>
                            Guardar Cotización
                        </button>

                    </div>
                    <div class="form-group col-md-3">

                        <button (click)="limpiar()" type="button" class="btn btn-warning mr-2">
                            <i class="fas fa-eraser"></i>
                            Limpiar
                        </button>

                    </div>

                    <div class="form-group col-3">
                        <label class="col-form-label"> Total $ </label>
                    </div>
                    <div class="form-group col-3">
                        <input type="number" #txtvalorNeto class="form-control" placeholder="Total"
                            [formControl]="totalControl" [(ngModel)]="valorTotal" name="valorTotal">
                    </div>
                </div>
            </div>
        </div>

    </div>
</div>



<!-- <button *ngIf="cargando" disabled class="btn btn-success mr-2">
    <i class="fa fa-spin fa-sync"></i>
    Espere por favor...
    </button> 

            <button type="button" (click)="back()" class="btn btn-light">
    <i class="fas fa-backward"></i>
    Volver</button>  -->