import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { AppState } from '../../../../store/app.reducer';

import * as usuarioActions from '../../../../store/actions';
import { Usuario } from '../../../../models/usuario.model';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { UsuarioService } from '../../../../services/usuario.service';
import Swal from 'sweetalert2';
import { Empresas } from '../../../../models/empresa.model';
import { Codigo } from '../../../../models/codigo.model';

@Component({
  selector: 'app-usuario',
  templateUrl: './ingreso-personal.component.html',
  styles: []
})
export class UsuarioComponent implements OnInit {

  dd = new Date();
  tipo = 'inactivo';
  usuarios: Usuario[] = [];
  codigo: Codigo = new Codigo();
  codigoQ: Codigo[] = [];
  empresas: Object;
  user: Usuario;
  loading: boolean;
  error: any;
  Empresa: any;
  cod: Object;
  code;
  selected: Empresas["Codigo"];
  login: string;

  constructor( private router: ActivatedRoute,
               private usuarioService: UsuarioService,
               private route: Router,
               private store: Store<AppState>) { }

  forma: FormGroup = new FormGroup({
  'codigo': new FormControl('', Validators.required ),
  'rut': new FormControl('', Validators.required ),
  'nombre': new FormControl('', Validators.required ),
  'empresa': new FormControl('', Validators.required ),
  'descripcion': new FormControl('', Validators.required )});

  ngOnInit() {
    this.login = localStorage.getItem('login')
    this.getEmpresa()
    this.cod = [{}];
    this.router.params
      .subscribe( params => {
        const id = params['id'];
        this.store.dispatch( new usuarioActions.CargarUsuario(id) );
      });

    this.store.select('usuario')
        .subscribe( usuario => {
          this.user = usuario.user;
          this.loading = usuario.loading;
          this.error = usuario.error;

        });
  }

  getEmpresa(){
    this.usuarioService.getEmpresa().subscribe( resp => {
      this.empresas = resp; 
    });

  }



  back(){
    this.route.navigate(['/home']);

  }


  limpiar(){
    this.forma.reset({ monto: 0 });
  }

  buscarCodigoQR(codigoQR: string){
  this.usuarioService.getCodigoQR(codigoQR)
  .subscribe( resp => {
    this.cod = resp;
    if(this.cod  === undefined){
    } else {
      Swal.fire('Codigo QR', codigoQR, 'success');
    }
  });
  }

  buscarRut(rut: string){
    this.usuarioService.getRut(rut)
    .subscribe( resp => {
      this.cod = resp;
      if(this.cod  === undefined){
        Swal.fire('rut', rut, 'success');
      } else{
      }
    });
    }






  crearIngreso(codigo: number, rut: any, nombre: any, empresa: number, otro: any, estado: number){

    if(this.tipo === "activo"){
      estado = 0;
    }
    else{
      estado = 1;
    }
    const fechaDay = this.dd.getDate();

    // const latest_date =this.datepipe.transform(fecha, 'dd-MM-yyyy');
    this.usuarioService.ingresoPersonal(codigo,rut,nombre,empresa,otro,estado)
    .subscribe( resp => {
      const codigoIngresado = nombre;
      this.usuarios = resp;
      // Swal.fire({
      //   title: 'Espere',
      //   text: 'Guardando información',
      //   type: 'info',
      //   allowOutsideClick: false
      // });
      Swal.showLoading();

      Swal.fire('Ingresado Correctamente', codigoIngresado.toString(), 'success');
      this.forma.reset({ monto: 0 });

    });

  }

}
