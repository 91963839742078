<app-Loginnavbar></app-Loginnavbar>
<br><br>
<form novalidate [formGroup]="limpiarForm">

    <div class="form-row">

        <div class="form-group col-md-4">
            <label for="inputEmail4">FECHA</label>
            <mat-form-field class="form-control">
                <input [(ngModel)]="selectedFecha" #txtInput1 matInput [matDatepicker]="picker" formControlName="fecha" placeholder="Fecha">
                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                <mat-datepicker #picker></mat-datepicker>
            </mat-form-field>
        </div>
        <div class="form-group col-md-4">
            <label for="inputPassword4">HORA</label>
            <mat-form-field class="form-control">
                <mat-label>Seleccione la hora</mat-label>
                <mat-select [(ngModel)]="selectedHora" #txtInput2 formControlName="hora">
                    <mat-option *ngFor="let hora of horas" value="{{ hora.HORA }}">{{ hora.HORA }}</mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        <div class="form-group col-md-4">
            <label for="inputPassword4">PATENTE</label>
            <mat-form-field class="form-control">
                <mat-label>Seleccione la Patente</mat-label>
                <mat-select [(ngModel)]="selectedPatente" #txtInput3 formControlName="patente">
                    <mat-option *ngFor="let patente of patente" value="{{ patente.PATENTE }}">{{ patente.PATENTE }}</mat-option>
                </mat-select>
            </mat-form-field>
        </div>

    </div>
    <div>
        <div class="form-inline my-2 my-lg-2">
           <!--  <button mat-raised-button (click)="irUsuario( selectedFecha,selectedHora,selectedPatente )" class="btn btn-login my-2 my-sm-0" type="submit">Buscar</button> -->
        </div>
        <div class="form-inline my-2 my-lg-2">
            <button mat-raised-button (click)="limpiar()" class="btn btn-outline-warning my-2 my-sm-0 " type="button ">Limpiar</button>
        </div>
    </div>
</form>

<br>
<div *ngIf="loading===false">
    <button mat-raised-button (click)="exportAsXLSX()" class="btn btn-outline-info my-2 my-sm-0" type="submit">Exportar
        <i class="fas fa-file-export"></i>
    </button>
    <table class="table mt-1 animated fadeIn faster animated fadeIn faster ">
        <thead class="thead-dark ">
            <tr>
                <th scope="col ">Codigo QR</th>
                <th scope="col ">Rut</th>
                <th scope="col ">Nombre</th>
                <th scope="col ">Conductor</th>
                <th scope="col ">Fecha</th>
                <th scope="col ">Hora</th>
                <th scope="col ">Origen</th>
                <th scope="col ">Destino</th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let user of usuarios; let i=index ">
                <td>{{ user.CODIGO_QR }}</td>
                <td>{{ user.RUT }}</td>
                <td>{{ user.NOMBRE }}</td>
                <td>{{ user.CHOFER }}</td>
                <td>{{ user.FECHA }}</td>
                <td>{{ user.HORA }}</td>
                <td>{{ user.ORIGEN }}</td>
                <td>{{ user.DESTINO }}</td>
            </tr>
        </tbody>
    </table>
</div>

<!-- <div class="row " *ngIf="loading===t rue ">
    <div class="col ">
        <div class="alert alert-info text-center ">
            <i class="fa fa-sync fa-spin fa-2x "></i>
            <h1>Buscando datos...</h1>
        </div>
    </div>
</div> -->
<div class="row " *ngIf="user===true ">
    <div class="col ">
        <div class="alert alert-info text-center ">
            <!-- <i class="fa fa-sync fa-spin fa-2x "></i> -->
            <h1>No se encontraron datos...</h1>
        </div>
    </div>
</div>

<div class="row " *ngIf="error ">
    <div class="col ">
        <div class="alert alert-danger text-center ">
            <i class="fa fa-times fa-2x "></i>
            <h2>Error: {{ error.status }} </h2>
            <p>
                {{ error.message }}
                <br><br> {{ error.url }}
            </p>
        </div>
    </div>
</div>