import { Routes } from '@angular/router';
import { PersonalComponent } from '../usuarios/personal/personal.component';
import { UsuarioComponent } from '../usuarios/ingreso-personal/ingreso-personal.component';
import { LoginComponent } from '../auth/login/login.component';
import { CasinoComponent } from '../usuarios/casino/casino.component';
import { UbicacionComponent } from '../usuarios/ubicacion/ubicacion.component';
import { ConductorComponent } from '../usuarios/conductor/conductor.component';
import { ClientesComponent } from '../usuarios/clientes/clientes.component';
import { ServiciosComponent } from '../usuarios/servicios/servicios.component';





export const dashboardRoutes: Routes = [

 { path: 'home', component: PersonalComponent },
 { path: 'personal', component: UsuarioComponent },
 { path: 'casino', component: CasinoComponent },
 { path: 'ubicacion', component: UbicacionComponent },
 { path: 'personal', component: ConductorComponent },
 { path: 'conductor', component: ConductorComponent },
 { path: 'cliente', component: ClientesComponent },
 { path: 'servicio', component: ServiciosComponent },



//  { path: 'login', component: LoginComponent },

];
