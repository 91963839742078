<div class="row">
    <div class="col-12">
        <div class="card">
            <div class="card-body" *ngFor="let codigos of cod">
                <!-- <h4 class="card-title">Agregar Costo y Venta</h4> -->
                <!-- <p class="card-description">
                    Especifique el monto, codigo, descripcion y el tipo
                </p> -->

                <form [formGroup]="forma">

                    <!-- <div class="form-group">
                        <label>Fecha</label>
                        <input type="date" class="form-control" formControlName="fecha" name="fecha" />
                    </div> -->

                    <div class="form-row">
                        <div class="form-group col-md-6">

                            <label>Codigo QR</label>
                            <input type="text" #txtCodigo class="form-control" placeholder="Codigo" formControlName="codigo" [(ngModel)]="codigos.CODIGO" name="codigo">
                            <button (click)="buscarCodigoQR(txtCodigo.value)" type="button" class="btn btn-primary btn-block">Buscar Codigo QR</button>
                        </div>
                        <!-- <div class="form-group col-md-2">
                        </div> -->
                        <div class="form-group col-md-6">

                            <label>Rut:</label>
                            <input type="text" #txtRut class="form-control" placeholder="Rut" formControlName="rut" [(ngModel)]="codigos.RUT" name="rut">
                            <button (click)="buscarRut(txtRut.value)" type="button" class="btn btn-primary btn-block">Buscar Rut</button>
                        </div>
                    </div>

                    <div class="form-group">
                        <label>Nombre Completo:</label>
                        <input type="text" #txtNombre class="form-control" placeholder="Nombre" formControlName="nombre" [(ngModel)]="codigos.NOMBRE" name="nombre">
                    </div>

                    <!-- <div class="form-row">
                        <div class="form-group col-md-7">

                            <label>Empresa:</label>
                            <input type="text" #txtEmpresa class="form-control" placeholder="Empresa" formControlName="empresa" [(ngModel)]="codigos.EMPRESA" name="empresa">
                        </div>
                    </div> -->

                    <div class="form-group col-md-4">
                        <label for="inputPassword4">EMPRESA</label>
                        <mat-form-field class="form-control">
                            <mat-label>Seleccione: </mat-label>
                            <mat-select [(ngModel)]="codigos.EMPRESA" value="{{ codigos.EMPRESA }}" #txtEmpresa formControlName="empresa">
                                <mat-option *ngFor="let empresa of empresas" value="{{ empresa.Codigo }}">{{ empresa.Empresa }}</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>


                    <div class="form-group">
                        <label>Descripcion:</label>
                        <input type="text" #txtDescripcion class="form-control" placeholder="Descripcion" formControlName="descripcion" [(ngModel)]="codigos.OTRO" name="descripcion">
                    </div>
                    <div class="card-body">

                        <div class="form-group">
                            <label>Estado</label>
                            <div class="form-group col-md-7" #txtEstado>
                                <button (click)="tipo='activo'" *ngIf="tipo ==='inactivo'" type="button" class="btn btn-success btn-block">Activo</button>
                                <button (click)="tipo='inactivo'" *ngIf="tipo ==='activo'" type="button" class="btn btn-danger btn-block">Inactivo</button>
                            </div>
                        </div>
                    </div>
                    <hr>
                    <div class="card-body">
                        <div class="form-row">
                            <div class="form-group col-md-4">
                                <button (click)="crearIngreso(txtCodigo.value,txtRut.value,txtNombre.value,txtEmpresa.value,txtDescripcion.value, txtEstado.value)" [disabled]="forma.invalid" type="submit" class="btn btn-success mr-2">
                    <i class="fa fa-save"></i>
                    Guardar Cotización
                    </button>
                            </div>
                            <div class="form-group col-md-4">

                                <button (click)="limpiar()" type="button" class="btn btn-warning mr-2">
                    <i class="fas fa-eraser"></i>                   
                     Limpiar
                    </button>
                            </div>
                            <div class="form-group col-md-4">

                                <!-- <button *ngIf="cargando" disabled class="btn btn-success mr-2">
                    <i class="fa fa-spin fa-sync"></i>
                    Espere por favor...
                    </button> -->

                                <button type="button" (click)="back()" class="btn btn-light">
                    <i class="fas fa-backward"></i>
                    Volver</button>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>