import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UsuarioService } from '../services/usuario.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styles: []
})
export class DashboardComponent implements OnInit {

  constructor(private router: Router, public UsuarioService: UsuarioService) { }

  ngOnInit() {
    // this.router.navigate(['/login']);
  }

  isAuth() {
    return this.router.navigate(['/login']);
  }

  canActivate() {
    return this.UsuarioService.isAuth();
  }

}
