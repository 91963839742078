<nav class="navbar navbar-light" style="background-color: #e3f2fd;">
    <div class="text-center navbar-brand-wrapper d-flex align-items-top justify-content-center">
        <a class="navbar-brand brand-logo">
            <img src="assets/imagenes/logo_2023_4.png" alt="logo" />
        </a>
    </div>

    <!-- <div class="collapse navbar-collapse" id="navbarSupportedContent"> -->
    <ul class="navbar-nav mr-auto">

    </ul>
    <ul class="navbar-nav mr-auto">
        <!-- <li class="nav-item" routerLinkActive="active">
            <a class="nav-link" (click)="loggout()">Cerrar Sesion</a>
        </li> -->
    </ul>




</nav>